import React, { useState, useEffect, useRef } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { isValid } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroller'
import { getOrders, setPastSearchResultsSnackbar } from './pastDeliveries.redux'
import { setSearchResultsFound } from '../searchResults/searchResults.redux'
import { useSelector, useDispatch } from 'react-redux'
import { Close } from '@material-ui/icons'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { frCA, enCA } from 'date-fns/locale'
import { LANGUAGE } from '../../const'
import {
  CardContent,
  makeStyles,
  Toolbar,
  Typography,
  Card,
  Grid,
  Button,
  IconButton,
  Snackbar,
} from '@material-ui/core'
import { OrderCards } from '../orderCards/orderCards'
import { fetchPastDeliveriesOnScroll } from './pastDeliveries.io'
import { groupTripsByDate, countCompletedTrip } from './pastDeliveriesHelper'
import { LoadingScreen } from '../../loading/loading'
import { CancelTripDialog } from '../cancelTrip/cancelTripDialog'
import { pastDeliveriesMap } from './pastDeliveriesContext'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 15,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export const PastDeliveries = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const { orders, showPastSearchResultsSnackbar } = useSelector(state => state.past)
  const { token, selectedStore, language } = useSelector(state => state.auth)
  const { searchResultsFound } = useSelector(state => state.search)
  const [pastDeliveriesOnScroll, setPastDeliveriesOnScroll] = useState([])
  const [hasMorePastDeliveries, setHasMorePastDeliveries] = useState(true)
  const [renderInfinteScroll, setRenderInfinteScroll] = useState(true)
  const [isSearchByDates, setIsSearchByDates] = useState(false)
  const isMounted = useRef(false)

  const handleFromDateChange = date => {
    setFromDate(date)
  }

  const handleToDateChange = date => {
    setToDate(date)
  }

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setPastSearchResultsSnackbar(false))
  }

  const getPastDeliveriesOnScroll = async page => {
    try {
      const { data } = await fetchPastDeliveriesOnScroll(token, page, 7, selectedStore.id)
      setHasMorePastDeliveries(data.length > 0)
      setPastDeliveriesOnScroll([...pastDeliveriesOnScroll, ...data])
    } catch (err) {
      console.log('error:', err)
    }
  }

  /*
   * Rerendering pastDeliveriesOnScroll (InfinteScroll) when a different store was selected
   * Setting dependent states to the default state
   *
   * isMounted.current => checks whether the component is mounted or not
   *
   * setIsSearchByDates:false => clears if any search results displayed when user searches with dates
   * setSearchResultsFound:false => clears if any search results displayed when user searched with orderId
   *
   * setPastDeliveriesOnScroll => clears if any results displayed when user scrolls (With InfiniteScroll Component)
   * setHasMorePastDeliveries: true => setted true so that InfiniteScroll component will call getPastDeliveriesOnScroll function
   *
   * setRenderInfinteScroll =>  a small tweak used to tell the react to rerender InfiniteScroll Component
   */
  useEffect(() => {
    setFromDate(null)
    setToDate(null)

    if (isMounted.current) {
      setIsSearchByDates(false)
      dispatch(setSearchResultsFound(false))

      setPastDeliveriesOnScroll([])
      setRenderInfinteScroll(false)
      setHasMorePastDeliveries(true)

      setTimeout(() => {
        setRenderInfinteScroll(true)
      }, 100)
    } else {
      isMounted.current = true
    }
  }, [selectedStore])

  //setting searchResultsfound to false in component unmount stage
  useEffect(() => {
    return () => dispatch(setSearchResultsFound(false))
  }, [])

  return (
    <>
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" component="div">
            {pastDeliveriesMap.title[language]}
          </Typography>
        </Toolbar>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <MuiPickersUtilsProvider locale={language === LANGUAGE.EN ? enCA : frCA} utils={DateFnsUtils}>
              <Grid item>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-from-picker-inline"
                  label={pastDeliveriesMap.dateFrom[language]}
                  value={fromDate}
                  disableFuture={true}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date from',
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-to-picker-inline"
                  label={pastDeliveriesMap.dateTo[language]}
                  value={toDate}
                  disableFuture={true}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date to',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid(fromDate) || !isValid(toDate)}
                onClick={() => {
                  setIsSearchByDates(true)
                  return dispatch(getOrders({ fromDate, toDate, selectedStore }))
                }}
              >
                {pastDeliveriesMap.search[language]}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {isSearchByDates || searchResultsFound ? (
        <>
          <Grid container alignItems="center" justify="center">
            {groupTripsByDate(orders).map(pastDeliveries => (
              <OrderCards
                data={pastDeliveries.trips}
                title={pastDeliveries.date[language]}
                key={pastDeliveries.date[language]}
                orderBy={'updatedAt'}
                cardCount={countCompletedTrip(pastDeliveries.trips)}
              />
            ))}
          </Grid>
          <CancelTripDialog />
        </>
      ) : (
        renderInfinteScroll && (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={getPastDeliveriesOnScroll}
              hasMore={hasMorePastDeliveries}
              loader={
                <div className="loader" key={new Date()}>
                  <LoadingScreen />
                </div>
              }
            >
              <Grid container alignItems="center" justify="center">
                {groupTripsByDate(pastDeliveriesOnScroll).map(pastDeliveries => (
                  <OrderCards
                    data={pastDeliveries.trips}
                    title={pastDeliveries.date[language]}
                    key={pastDeliveries.date[language]}
                    orderBy={'updatedAt'}
                    cardCount={countCompletedTrip(pastDeliveries.trips)}
                  />
                ))}
              </Grid>
            </InfiniteScroll>
            <CancelTripDialog />
          </>
        )
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showPastSearchResultsSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={pastDeliveriesMap.noResult[language]}
        action={
          <>
            <Button color="secondary" size="small" onClick={handleClose}>
              OK
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  )
}
