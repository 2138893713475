import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'partial.lenses'
import momentTz from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { determineOrderStatus, isThresholdWarningNeeded } from '../../utils/general'
import { formatDateAndTime, formatTime, fullAddress, formatOrderItems, priceOfRide } from '../../utils/string'
import { CancelTripButton } from '../cancelTrip/cancelTripButton'
import { AWAITING_PICKUP_FILTER } from '../../const'
import { cancellationReasonMap, orderCardsMap } from './orderCardsContext'

const useStyles = makeStyles(theme => ({
  root: {
    'margin-bottom': '10px',
    width: '59%',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      'padding-left': '10px',
      width: '100%',
    }
  },
  topCard: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      paddingLeft: '20px'
    }
  },
  button: {
    'justify-content': 'flex-end',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
      'padding-right': '20px'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  orderInfo: {
    width: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
  },
  orderStatus: {
    color: 'green',
    'font-weight': 'bold',
    'font-size': '14px',
    '& svg': {
      'margin-left': '10px',
      'margin-bottom': '-2px',
    },
    [theme.breakpoints.down('md')]: {
      'margin-bottom': '10px',
    }
  },
  orderHeadline: {
    'font-size': '18px',
    width: '80%',
    'font-weight': 'bold',
    [theme.breakpoints.down('md')]: {
      'font-size': '14px',
      'margin-bottom': '10px'
    }
  },
  subHeadline: {
    color: '#444545',
    [theme.breakpoints.down('md')]: {
      'font-size': '14px'
    }
  },

  driverInfo: {
    width: '200px',
  },
  licensePlate: {
    'font-weight': 'bold',
    'font-size': '16px',
    display: 'flex',
    'align-items': 'center',
    '& svg': {
      'margin-right': '10px',
    },
  },
  carBrand: {
    color: '#444545',
    display: 'flex',
    'align-items': 'center',
    '& div': {
      'margin-right': '10px',
    },
  },

  driverName: {
    'font-weight': 'bold',
    'font-size': '14px',
  },

  ridePrice: {
    display: 'flex',
    'align-items': 'center',
    'font-size': '18px',
    'font-weight': 'bold',
  },
  sectionTitle: {
    'font-weight': 'bold',
    'padding-top': '15px',
  },
  cardContent:{
    [theme.breakpoints.down('md')]: {
      padding: 'inherit',
      'padding-right': '5px'
    }
  },
  expandedComponent: {
    display: 'flex',
    'justify-content': 'space-between',
  },
  expandedTripInfo: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  trackingUrlLink: {
    '& a': {
      'text-decoration': 'underline',
      color: 'black',
    },
  },
  fullDriverInfo: {
    'padding-right': '70px',
    [theme.breakpoints.down('md')]: {
      padding: 'inherit'
    }
  },
  driver: {
    display: 'flex',
  },

  driverAndCancelButton: {
    display: 'flex',
    flexDirection: 'column',
  },

  lastUpdate: {
    'font-weight': 'bold',
  },
}))

const orderStatusMessage = (latestOrderStatus, timeEstimates, updatedAt, storeTimeZone, language) => {
  let status
  switch (determineOrderStatus(latestOrderStatus)) {
    case 'IN_PREPARATION':
      status = orderCardsMap.orderStatusMessage.preparation[language]
      break
    case 'AWAITING_PICKUP':
      status =
        timeEstimates && timeEstimates.eta
          ? orderCardsMap.orderStatusMessage.awaitingPickup[language].replace('[insertTime]', timeEstimates.eta)
          : ''
      break
    case 'ARRIVED_AT_PICKUP':
      status = orderCardsMap.orderStatusMessage.arrivedAtPickUp[language]

      break
    case 'OUT_FOR_DELIVERY':
      status =
        timeEstimates && timeEstimates.etd
          ? orderCardsMap.orderStatusMessage.outForDelivery[language].replace('[insertTime]', timeEstimates.etd)
          : ''
      break
    case 'RETURN':
      status = orderCardsMap.orderStatusMessage.return[language]
      break
    case 'ARRIVED_AT_DROPOFF':
      status = orderCardsMap.orderStatusMessage.arrivedAtDropoff[language]
      break
    case 'CANCELLED':
      status = orderCardsMap.orderStatusMessage.cancelled[language].replace(
        '[insertTime]',
        formatTime(updatedAt, storeTimeZone)
      )
      break
    case 'COMPLETED':
      status = orderCardsMap.orderStatusMessage.completed[language].replace(
        '[insertTime]',
        formatTime(updatedAt, storeTimeZone)
      )
      break
    default:
      status = ''
      break
  }
  return status
}

const tripAwaitingDriverAssignment = status =>
  status === 'ORDER_CREATED_IN_SFCC' || status === 'SCHEDULED' || status === 'WAITING_FOR_DRIVER_TO_ACCEPT'

export const ProgressOrderCard = ({ data }) => {
  const classes = useStyles()
  const [isExpanded, setExpanded] = useState(false)
  const handleExpandClick = () => setExpanded(!isExpanded)
  const { selectedStore, userInfo, language } = useSelector(state => state.auth)

  const customerAddress = data.customerAddress || (data.orderDetails && data.orderDetails.customerAddress)
  const clientFullAddress = customerAddress && fullAddress(customerAddress)
  const orderItems =
    data.latestOrderStatus === 'ORDER_CREATED_IN_SFCC' ? formatOrderItems(data) : formatOrderItems(data.orderDetails)
  const costTrip = get(['tripDetails', 'full_fee', 'total'], data) / 100 || '0.00'
  const showThresholdWarning = isThresholdWarningNeeded(data, momentTz(), selectedStore)

  return (
    <Card className={classes.root}>
      <CardContent className={classes.topCard} onClick={handleExpandClick}>
        <div className={classes.orderInfo}>
          <div className={classes.orderStatus}>
            {orderStatusMessage(
              data.latestOrderStatus,
              data.timeEstimates,
              data.updatedAt,
              selectedStore.timezone,
              language
            )}
            {showThresholdWarning && <WarningRoundedIcon color="secondary" />}
          </div>
          <div className={classes.orderHeadline}>
            {`${orderCardsMap.headline[language].replace('[orderId]', data.orderId)}
            ${customerAddress && customerAddress.Address1}`}
          </div>
          <div className={classes.subHeadline}>
            {`${data.customerFirstName || (data.orderDetails && data.orderDetails.customerFirstName)}
                  ${data.customerLastName || (data.orderDetails && data.orderDetails.customerLastName)}`}
          </div>
        </div>
        <div className={classes.driverAndCancelButton}>
          {tripAwaitingDriverAssignment(data.latestOrderStatus) || !data.driverInfo ? null : (
            <div className={classes.driverInfo}>
              <div className={classes.licensePlate}>
                <DriveEtaIcon />
                {get(['driverInfo', 'vehicle', 'license_plate'], data)}
              </div>
              <div className={classes.carBrand}>
                {`${get(['driverInfo', 'vehicle', 'make'], data)} ${get(['driverInfo', 'vehicle', 'model'], data)}`}
              </div>
              <div className={classes.driverName}>{get(['driverInfo', 'contact', 'first_name'], data)}</div>
            </div>
          )}
        </div>
        {AWAITING_PICKUP_FILTER.latestOrderStatus.includes(data.latestOrderStatus) &&
          userInfo.role.cata({
            Admin: () => <CancelTripButton tripId={data.carrierTripId} language={language} />,
            DistrictSupervisor: () => <CancelTripButton tripId={data.carrierTripId} language={language} />,
            Manager: () => <CancelTripButton tripId={data.carrierTripId} language={language} />,
            Employee: () => null,
            None: () => null,
          })}

        <CardActions disableSpacing className={classes.button}>
          <IconButton
            className={isExpanded ? classes.expandOpen : null}
            aria-expanded={isExpanded}
            aria-label={orderCardsMap.showMoreLabel[language]}
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
        </CardActions>
      </CardContent>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
          <div className={classes.expandedComponent}>
            <div className={classes.expandedTripInfo}>
              {data.latestOrderStatus === 'ORDER_CREATED_IN_SFCC' ? null : (
                <div>
                  <div className={classes.ridePrice}>
                    {userInfo.role.cata({
                      Admin: () => priceOfRide(language, customerAddress.Country, costTrip),
                      DistrictSupervisor: () => priceOfRide(language, customerAddress.Country, 10),
                      Manager: () => priceOfRide(language, customerAddress.Country, 10),
                      Employee: () => priceOfRide(language, customerAddress.Country, 10),
                      None: () => null,
                    })}
                  </div>
                  {data && data.carrierTrackingUrl && (
                    <div className={classes.trackingUrlLink}>
                      <a href={data.carrierTrackingUrl} target="_blank" rel="noreferrer">
                        {orderCardsMap.linkToTrip[language]}
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div>
                <div className={classes.sectionTitle}>{orderCardsMap.itemList[language]}</div>
                <div className={classes.tripItems}>
                  {orderItems.map((item, index) => {
                    return <div key={index}>{item}</div>
                  })}
                </div>
              </div>
              <div>
                <div className={classes.sectionTitle}>{orderCardsMap.customer[language]} </div>
                <div>
                  {`${data.customerFirstName || (data.orderDetails && data.orderDetails.customerFirstName)}
                  ${data.customerLastName || (data.orderDetails && data.orderDetails.customerLastName)}`}
                </div>
                <div> {clientFullAddress}</div>
                <div> {data.customerPhone || (data.orderDetails && data.orderDetails.customerPhone)}</div>
              </div>
              {data.pickupInstruction && (
                <div>
                  <div className={classes.sectionTitle}>{orderCardsMap.pickUpInstructions[language]}</div>
                  {data.pickupInstruction}
                </div>
              )}
              {data.dropoffInstructions && (
                <div>
                  <div className={classes.sectionTitle}>{orderCardsMap.dropOffInstructions[language]}</div>
                  {data.dropoffInstructions}
                </div>
              )}
              {data.cancellationReason && (
                <div>
                  <div className={classes.sectionTitle}>{orderCardsMap.cancellationReason[language]}</div>
                  {cancellationReasonMap[data.cancellationReason][language]}
                </div>
              )}
            </div>
            {tripAwaitingDriverAssignment(data.latestOrderStatus) || !data.driverInfo ? null : (
              <div className={classes.fullDriverInfo}>
                <div className={classes.driver}>
                  <div className={classes.driverName}>{get(['driverInfo', 'contact', 'first_name'], data)}</div>
                </div>
                <div className={classes.carBrand}>
                  {`${get(['driverInfo', 'vehicle', 'make'], data)} ${get(['driverInfo', 'vehicle', 'model'], data)}`}
                </div>
                <div className={classes.fullDriverInfoLicensePlace}>
                  {get(['driverInfo', 'vehicle', 'license_plate'], data)}
                </div>
              </div>
            )}
          </div>
          <div className={classes.sectionTitle}>
            {orderCardsMap.storeNumber[language]}: {data.storeId}
          </div>
          <div className={classes.lastUpdate}>
            {orderCardsMap.lastUpdate[language]}: {formatDateAndTime(data.updatedAt, selectedStore.timezone)}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}
