import { ProgressOrderCard } from './progressOrderCard'
import { CompletedOrderCard } from './completedOrderCard'
import { determineOrderStatus } from '../../utils/general'
import { blankCardDescription } from '../../utils/string'
import { makeStyles, Typography } from '@material-ui/core'
import { getComparator, stableSort } from '../../utils/sort'
import { useSelector } from 'react-redux'
import { orderCardsMap } from './orderCardsContext'

const useStyles = makeStyles(theme => ({
  cardsTitle: {
    'margin-right': 'auto',
    'margin-left': '20.5%',
    width: '100%',
    'margin-bottom': '15px',
    'padding-right': '20.5%',
    display: 'flex',
    'justify-content': 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      'font-size': '14px',
      'margin-left': '20px',
      'font-weight': 'bold',
      'padding-right': '0px',
    }
  },
  orderCompleted: {
    [theme.breakpoints.down('md')]: {
      'font-weight': 'normal'
    }
  },
  emptyCard: {
    'background-color': '#eeeeee',
    color: '#7a7d87',
    'margin-bottom': '20px',
    width: '59%',
    height: '100px',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
  },
}))

export const OrderCards = ({ data, title, orderBy, cardCount = -1 }) => {
  const classes = useStyles()
  const { language } = useSelector(state => state.auth)

  let order = 'asc'
  let sortedData = stableSort(data, getComparator(order, orderBy))

  return (
    <>
      {title && (
        <Typography className={classes.cardsTitle} variant="h6" component="div">
          <div>{title}</div>
          {cardCount >= 0 && (
            <div className={classes.orderCompleted}>
              {orderCardsMap.completedCount[language]}: {cardCount}
            </div>
          )}
        </Typography>
      )}
      {sortedData.length > 0 ? (
        sortedData.map(singleOrder =>
          determineOrderStatus(singleOrder.latestOrderStatus) === 'COMPLETED' ? (
            <CompletedOrderCard data={singleOrder} key={singleOrder._id.toString()} />
          ) : (
            <ProgressOrderCard data={singleOrder} key={singleOrder._id.toString()} />
          )
        )
      ) : (
        <div className={classes.emptyCard}>{blankCardDescription(title.toLowerCase(), language)}</div>
      )}
    </>
  )
}
