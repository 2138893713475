import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const loginUser = code =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `/login`,
      data: {
        code,
      },
      baseURL,
    })
  )
