import { createAction, handleActions } from 'redux-actions'

export const getDistrictSupervisors = createAction('DSS_CONFIGURATION_GET_DISTRICT_SUPERVISORS')
export const setSupervisorList = createAction('DSS_CONFIGURATION_SET_SUPERVISOR_LIST')
export const setIsLoading = createAction('DSS_CONFIGURATION_SET_IS_LOADING')
export const updateSupervisorList = createAction('DSS_CONFIGURATION_UPDATE_SUPERVISOR_LIST')
export const setSnackbar = createAction('DSS_CONFIGURATION_SHOW_SNACKBAR')
export const setIsButtonDisabled = createAction('DSS_CONFIGURATION_SET_BUTTON_DISABLED')

const INITIAL_STATE = {
  supervisors: {},
  isLoading: false,
  snackbar: {
    show: false,
    success: null,
    message: null,
  },
  isButtonDisabled: true,
}

export const createSupervisorMap = supervisors =>
  (supervisors || []).reduce((acc, supervisor) => {
    acc[supervisor.id] = { id: supervisor.id, displayName: supervisor.displayName }
    return acc
  }, {})

export const isValidEntry = (option, value) =>
  value === '' || !Object.keys(value).length || option.id === value.id ? true : false

export const dssConfigurationReducer = handleActions(
  {
    [setSupervisorList]: (state, { payload }) => ({
      ...state,
      supervisors: createSupervisorMap(payload),
    }),
    [setIsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    [setSnackbar]: (state, { payload }) => ({
      ...state,
      snackbar: {
        show: payload.show,
        success: payload.success,
        message: payload.message,
      },
    }),
    [setIsButtonDisabled]: (state, { payload }) => ({
      ...state,
      isButtonDisabled: payload,
    }),
  },
  INITIAL_STATE
)
