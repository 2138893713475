import React from 'react'
import { useSelector } from 'react-redux'
import { filterItemsByOrderStatus } from '../../utils/filter'
import { NoResults } from '../reusables/noResults'
import { LoadingScreen } from '../../loading/loading'
import { IN_PREPARATION_FILTER } from '../../const'
import { Grid } from '@material-ui/core'
import { OrderCards } from '../orderCards/orderCards'
import { todaysDeliveriesTitleMap } from '../todaysDeliveries/todaysDeliveriesContent'

export const InPreparation = () => {
  const { todaysDeliveries, isLoading } = useSelector(state => state.today)
  const { language } = useSelector(state => state.auth)
  const preparation = filterItemsByOrderStatus(todaysDeliveries.ordersInPreparation, IN_PREPARATION_FILTER)

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Grid container alignItems="center" justify="center">
      {preparation.length === 0 ? (
        <NoResults />
      ) : (
        <OrderCards data={preparation} title={todaysDeliveriesTitleMap.preparation[language]} orderBy={'updatedAt'} />
      )}
    </Grid>
  )
}
