import React, { useState, useEffect } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { FormControl, CircularProgress, TextField, InputAdornment, makeStyles } from '@material-ui/core'
import { colors } from '../../colors'
import { REACT_APP_GOOGLE_MAPS_API_KEY } from './../../const'

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '4px 0px',
    width: '100%',
    '& .MuiInput-root': {
      backgroundColor: colors.lightBlue,
      marginTop: '20px !important',
    },
    '& label': {
      color: colors.black,
    },
  },
  dropDownContainer: {
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%)',
    position: 'absolute',
    zIndex: '1000',
    width: '100%',
    marginTop: '50px',
    backgroundColor: colors.white,
  },
  suggestions: {
    fontSize: '16px',
    padding: '3px 5px',
  },
}))

const loadGoogleMapsScript = setIsLoaded => {
  const existingScript = document.getElementById('googleMaps')
  if (existingScript) {
    setIsLoaded()
  } else {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`
    script.id = 'googleMaps'
    script.defer = true
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      setIsLoaded()
    }
  }
}

export const GoogleAddressSuggestions = ({
  name,
  address,
  label,
  inputAdornment,
  changed,
  required,
  helperText,
  error,
  disabled,
}) => {
  const [isGoogleMapsScriptLoaded, setIsGoogleMapsScriptLoaded] = useState(false)
  const classes = useStyles()
  const [isShowSuggestions, setIsShowSuggestions] = useState(false)

  useEffect(() => {
    loadGoogleMapsScript(() => {
      setIsGoogleMapsScriptLoaded(true)
    })
  }, [])

  return (
    <div>
      {isGoogleMapsScriptLoaded && (
        <PlacesAutocomplete
          value={address}
          onChange={value => changed({ target: { name, value } })}
          onSelect={(value, placeId) => {
            changed({ target: { name, select: true, value: { value, placeId } } })
          }}
        >
          {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
            <FormControl className={classes.formControl}>
              <TextField
                {...getInputProps({ id: name })}
                multiline
                label={label}
                error={error}
                required={required}
                helperText={helperText}
                autoComplete="autoComplete"
                disabled={disabled}
                onFocus={() => setIsShowSuggestions(true)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{inputAdornment}</InputAdornment>,
                }}
              />
              <div className={classes.dropDownContainer} style={{ display: isShowSuggestions ? 'block' : 'none' }}>
                {loading && <CircularProgress />}
                {suggestions.map(suggestion => {
                  return (
                    <div
                      className={classes.suggestions}
                      style={{ backgroundColor: suggestion.active ? colors.lightBlue : colors.white }}
                      key={suggestion.index}
                      {...getSuggestionItemProps(suggestion, {})}
                    >
                      {suggestion.description}
                    </div>
                  )
                })}
              </div>
            </FormControl>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  )
}
