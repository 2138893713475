import React, { useState } from 'react'
import { get } from 'partial.lenses'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import {
  formatDateAndTime,
  formatTime,
  capitalizeFirstLetter,
  fullAddress,
  formatOrderItems,
  priceOfRide,
} from '../../utils/string'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { orderCardsMap } from './orderCardsContext'
import { getStoreById } from '../../utils/general'

const useStylesCompleted = makeStyles(theme => ({
  root: {
    'margin-bottom': '10px',
    width: '59%',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      paddingLeft: '10px',
      width: '100%',
    }
  },
  topCard: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      paddingLeft: '20px'
    }
  },
  button: {
    'justify-content': 'flex-end',
    [theme.breakpoints.down('md')]: {
    padding: '0px',
    'padding-right': '20px'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  orderInfo: {
    width: '40%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  orderStatus: {
    color: '#6f7070',
    'font-weight': 'bold',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      'margin-bottom': '10px'
    }
  },
  orderHeadline: {
    'font-size': '14px',
    width: '80%',
    'font-weight': 'bold',
    [theme.breakpoints.down('md')]: {
      'margin-bottom': '10px'
    }
  },

  deliveryRoute: {
    width: '40%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-around',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      'margin-bottom': '10px'
    }
  },
  tripLocation: {
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  circle: {
    width: '10px',
    height: '10px',
    'text-align': 'center',
    'line-height': '10px',
    'border-radius': '10px',
    background: '#a5a5a5',
    margin: '5px',
    position: 'relative',
  },
  square: {
    top: '-6px',
    width: '10px',
    height: '10px',
    'text-align': 'center',
    'line-height': '10px',
    background: '#a5a5a5',
    margin: '5px',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '-25px',
      left: '4px',
      width: '1px',
      height: '35px',
      background: '#a5a5a5',
    },
  },
  tripLocationUserAddress: {
    'padding-top': '8px',
  },

  tripCost: {
    display: 'flex',
    'align-items': 'center',
    'font-size': '18px',
    'font-weight': 'bold',
  },
  cardFooter: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      'justify-content': 'space-between'
    }
  },

  ridePrice: {
    display: 'flex',
    'align-items': 'center',
    'font-size': '18px',
    'font-weight': 'bold',
  },
  sectionTitle: {
    'font-weight': 'bold',
    'padding-top': '15px',
  },
  expandedComponent: {
    display: 'flex',
    'justify-content': 'space-between',
    [theme.breakpoints.down('md')]: {
      'flex-direction': 'column-reverse',
      'min-width': '180px'
    }
  },
  expandedTripInfo: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      'padding-right': '10px',
    }
  },
  trackingUrlLink: {
    '& a': {
      'text-decoration': 'underline',
      color: 'black',
    },
  },
  fullDriverInfo: {
    'padding-right': '70px',
    [theme.breakpoints.down('md')]: {
      'margin-bottom': '20px'
    }
  },
  driver: {
    display: 'flex',
  },

  lastUpdate: {
    'font-weight': 'bold',
    display: 'flex',
    'justify-content': 'space-between',
  },
}))

export const CompletedOrderCard = ({ data }) => {
  const classes = useStylesCompleted()
  const [isExpanded, setExpanded] = useState(false)
  const handleExpandClick = () => setExpanded(!isExpanded)
  const { selectedStore, userInfo, language } = useSelector(state => state.auth)
  const customerAddress = data.customerAddress || (data.orderDetails && data.orderDetails.customerAddress)

  const clientFullAddress = data.orderDetails.customerAddress && fullAddress(data.orderDetails.customerAddress)
  const orderItems = formatOrderItems(data.orderDetails)
  const costTrip = get(['tripDetails', 'full_fee', 'total'], data) / 100 || '0.00'
  const storeOrdererFrom = getStoreById(data.storeId, userInfo.stores)

  return (
    <Card className={classes.root}>
      <CardContent className={classes.topCard} onClick={handleExpandClick}>
        <div className={classes.orderInfo}>
          <div className={classes.orderHeadline}>
            {`${orderCardsMap.headline[language].replace('[orderId]', data.orderId)} 
            ${customerAddress && customerAddress.Address1}`}
          </div>
          <div className={classes.orderStatus}>
            {orderCardsMap.orderStatusMessage.completed[language].replace(
              '[insertTime]',
              formatTime(data.updatedAt, selectedStore.timezone)
            )}
          </div>
        </div>
        <div className={classes.deliveryRoute}>
          <div className={classes.tripLocation}>
            <div className={classes.circle} />
            {storeOrdererFrom.address1}
          </div>
          <div className={classes.tripLocation}>
            <div className={classes.square} />
            <div className={classes.tripLocationUserAddress}>
              <div>{customerAddress.Address1}</div>
              <div>
                {data.orderDetails.customerAddress && capitalizeFirstLetter(customerAddress.City)},{' '}
                {customerAddress.State}, {customerAddress.PostalCode}, {customerAddress.Country}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.cardFooter}>
          <div className={classes.tripCost}>
            {userInfo.role.cata({
              Admin: () => priceOfRide(language, customerAddress.Country, costTrip),
              DistrictSupervisor: () => priceOfRide(language, customerAddress.Country, 10),
              Manager: () => priceOfRide(language, customerAddress.Country, 10),
              Employee: () => priceOfRide(language, customerAddress.Country, 10),
              None: () => null,
            })}
          </div>
          <CardActions disableSpacing className={classes.button}>
            <IconButton
              className={isExpanded ? classes.expandOpen : null}
              aria-expanded={isExpanded}
              aria-label={orderCardsMap.showMoreLabel[language]}
            >
              <ExpandMoreIcon color="primary" />
            </IconButton>
          </CardActions>
        </div>
      </CardContent>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div className={classes.expandedComponent}>
            <div className={classes.expandedTripInfo}>
              <div>
                <div className={classes.ridePrice}>
                  {userInfo.role.cata({
                    Admin: () => priceOfRide(language, customerAddress.Country, costTrip),
                    DistrictSupervisor: () => priceOfRide(language, customerAddress.Country, 10),
                    Manager: () => priceOfRide(language, customerAddress.Country, 10),
                    Employee: () => priceOfRide(language, customerAddress.Country, 10),
                    None: () => null,
                  })}
                </div>
                {data && data.carrierTrackingUrl && (
                  <div className={classes.trackingUrlLink}>
                    <a href={data.carrierTrackingUrl} target="_blank" rel="noreferrer">
                      {orderCardsMap.linkToTrip[language]}
                    </a>
                  </div>
                )}
              </div>
              <div>
                <div className={classes.sectionTitle}>{orderCardsMap.itemList[language]}</div>
                <div className={classes.tripItems}>
                  {orderItems.map((item, index) => {
                    return <div key={index}>{item}</div>
                  })}
                </div>
              </div>
              <div>
                <div className={classes.sectionTitle}>{orderCardsMap.customer[language]} </div>
                <div>
                  {`${data.customerFirstName || (data.orderDetails && data.orderDetails.customerFirstName)}
                  ${data.customerLastName || (data.orderDetails && data.orderDetails.customerLastName)}`}
                </div>
                <div> {clientFullAddress}</div>
                <div> {data.customerPhone || (data.orderDetails && data.orderDetails.customerPhone)}</div>
              </div>
              {data.pickupInstruction && (
                <div>
                  <div className={classes.sectionTitle}>{orderCardsMap.pickUpInstructions[language]}</div>
                  {data.pickupInstruction}
                </div>
              )}
              {data.dropoffInstructions && (
                <div>
                  <div className={classes.sectionTitle}>{orderCardsMap.dropOffInstructions[language]}</div>
                  {data.dropoffInstructions}
                </div>
              )}
            </div>
            {!data.driverInfo ? null : (
              <div className={classes.fullDriverInfo}>
                <div className={classes.driver}>
                  <div className={classes.driverName}>{get(['driverInfo', 'contact', 'first_name'], data)}</div>
                </div>
                <div className={classes.carBrand}>
                  {`${get(['driverInfo', 'vehicle', 'make'], data)} ${get(['driverInfo', 'vehicle', 'model'], data)}`}
                </div>
                <div className={classes.fullDriverInfoLicensePlace}>
                  {get(['driverInfo', 'vehicle', 'license_plate'], data)}
                </div>
              </div>
            )}
          </div>
          <div className={classes.sectionTitle}>
            {orderCardsMap.storeNumber[language]}: {data.storeId}
          </div>
          <div className={classes.lastUpdate}>
            <div>
              {orderCardsMap.lastUpdate[language]}: {formatDateAndTime(data.updatedAt, selectedStore.timezone)}
            </div>
            <div>{data.processingTime && `${orderCardsMap.processingTime[language]}: ${data.processingTime}`}</div>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}
