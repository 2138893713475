import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'
import { getStoreIds } from '../../utils/general'

export const fetchStoresWithTodaysDeliveries = (token, fromDate, toDate, stores) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/getStoresWithTodaysDeliveries',
      data: {
        fromDate,
        toDate,
        storeIds: getStoreIds(stores),
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
