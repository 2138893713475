import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, Card, Typography, Button, TextField, Switch } from '@material-ui/core'
import { GridColDef, GridToolbar } from '@material-ui/data-grid'
import { DataGridTable } from '../reusables/dataGridTable'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { colors } from '../../colors'
import { moveDcUp } from '../../utils/sort'
import { onUpdateBulkStoreConfiguration } from '../auth/auth.redux'
import {
  isValidValue,
  isFormInvalid,
  getAllSameDayStores,
  setGlobalStoresSnackbar,
  setGlobalStoresIsLoading,
} from './storeConfiguration.redux'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import { Popup } from '../reusables/popup'
import { globalStoreConfigurationMap } from './globalStoreConfigurationContent'

const useStyles = makeStyles(() => ({
  heading: {
    paddingBottom: 40,
  },
  card: {
    height: 700,
    width: '100%',
  },
  button: {
    backgroundColor: colors.black,
    color: colors.white,
    width: 150,
    '&:hover': {
      backgroundColor: colors.black,
      color: colors.white,
    },
  },
  disabledButton: {
    backgroundColor: colors.fadedGrey,
  },
  errorText: {
    color: colors.red,
  },
  greenSwitch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.green,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.fadedGrey,
      },
    },
  },
}))

const StatusIcon = ({ isSet }) =>
  isSet ? <CheckIcon style={{ color: colors.green }} /> : <ClearIcon style={{ color: colors.red }} />

const Toolbar = ({ storesSnapshot, formHasNoEdits, setHasFormErrors, setGlobalStoresIsLoading }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { language } = useSelector(state => state.auth)

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <GridToolbar />
      <div style={{ padding: '10px' }}>
        <Button
          variant="contained"
          classes={{ disabled: classes.disabledButton, root: classes.button }}
          disabled={formHasNoEdits}
          onClick={() => {
            setHasFormErrors(false)
            if (!isFormInvalid(storesSnapshot)) {
              dispatch(setGlobalStoresIsLoading(true))
              dispatch(onUpdateBulkStoreConfiguration(Object.values(storesSnapshot)))
            } else {
              setHasFormErrors(true)
            }
          }}
        >
          {globalStoreConfigurationMap.submit[language]}
        </Button>
      </div>
    </div>
  )
}

export const GlobalStoreConfiguration = () => {
  const { stores } = useSelector(state => state.auth.userInfo)
  const { globalStoresSnackbar, globalStoresIsLoading } = useSelector(state => state.storeConfiguration)
  const { language } = useSelector(state => state.auth)

  const [storesSnapshot, setStoresSnapshot] = useState([])
  const [formHasNoEdits, setFormHasNoEdits] = useState(true)
  const [isPopupOpen, setisPopupOpen] = useState(false)
  const [popupSubmit, setPopupSubmit] = useState(false)
  const [navigation, setNavigation] = useState()
  const [hasFormErrors, setHasFormErrors] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getAllSameDayStores())
  }, [])

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (formHasNoEdits) {
        return true
      }
      setisPopupOpen(true)
      setNavigation(pathname)
      return false
    })

    if (formHasNoEdits && popupSubmit) {
      history.push(navigation)
    }

    return unblock
  }, [formHasNoEdits, popupSubmit])

  useEffect(() => {
    const storeMap = stores.reduce((acc, store) => {
      acc[store.id] = {
        id: store.id,
        mallName: store.mallName || store.storeName,
        banner: store.chainName,
        storeNumber: store.id,
        country: store.country,
        province: store.state,
        city: store.city,
        sameDayActivation: store.sameDayActivation,
        uberStoreId: !!store.uberStoreID,
        storeRadius: store.storeRadius || 0,
        sameDayExcludedPostalCodes: store.sameDayExcludedPostalCodes || [],
        radiusError: false,
        hasEdit: false,
      }
      return acc
    }, {})

    setStoresSnapshot(storeMap)
    setFormHasNoEdits(true)
  }, [stores])

  const columns: GridColDef[] = [
    { field: 'mallName', headerName: globalStoreConfigurationMap.mallName[language], width: 200 },
    { field: 'banner', headerName: globalStoreConfigurationMap.banner[language], width: 150 },
    { field: 'storeNumber', headerName: globalStoreConfigurationMap.storeNum[language], width: 150 },
    { field: 'country', headerName: globalStoreConfigurationMap.country[language], width: 150 },
    { field: 'province', headerName: globalStoreConfigurationMap.province[language], width: 150 },
    { field: 'city', headerName: globalStoreConfigurationMap.city[language], width: 150 },
    {
      field: 'sameDayActivation',
      headerName: globalStoreConfigurationMap.acceptSameDayOrders[language],
      width: 170,
      renderCell: ({ row }) => {
        return (
          <Switch
            className={classes.greenSwitch}
            checked={row.sameDayActivation}
            disabled={!row.uberStoreId}
            onChange={e => {
              setFormHasNoEdits(false)
              setStoresSnapshot({
                ...storesSnapshot,
                [row.id]: { ...storesSnapshot[row.id], sameDayActivation: e.target.checked, hasEdit: true },
              })
            }}
          />
        )
      },
      filterable: false,
    },
    {
      field: 'uberStoreId',
      headerName: globalStoreConfigurationMap.uberStoreID[language],
      width: 150,
      renderCell: ({ row }) => {
        return <StatusIcon isSet={row.uberStoreId} />
      },
      filterable: false,
    },
    {
      field: 'storeRadius',
      headerName: globalStoreConfigurationMap.radius[language],
      width: 200,
      filterable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <div>
              <TextField
                type="number"
                variant="outlined"
                className={classes.textField}
                InputProps={{ inputProps: { min: 0, max: 22 } }}
                value={row.storeRadius}
                error={storesSnapshot[row.id].error && formHasNoEdits}
                onChange={e => {
                  setFormHasNoEdits(false)
                  if (isValidValue(e.target.value)) {
                    setStoresSnapshot({
                      ...storesSnapshot,
                      [row.id]: {
                        ...storesSnapshot[row.id],
                        storeRadius: e.target.value,
                        radiusError: false,
                        hasEdit: true,
                      },
                    })
                  } else {
                    setStoresSnapshot({
                      ...storesSnapshot,
                      [row.id]: {
                        ...storesSnapshot[row.id],
                        storeRadius: e.target.value,
                        radiusError: true,
                        hasEdit: true,
                      },
                    })
                  }
                }}
              />
              {storesSnapshot[row.id].radiusError && hasFormErrors && (
                <div className={classes.errorText}>{globalStoreConfigurationMap.radiusValue[language]}</div>
              )}
            </div>
          </>
        )
      },
    },
    {
      field: 'sameDayExcludedPostalCodes',
      headerName: globalStoreConfigurationMap.postalCodeExclusion[language],
      width: 200,
      filterable: false,
      renderCell: ({ row }) => {
        return row.sameDayExcludedPostalCodes.length
          ? globalStoreConfigurationMap.yes[language]
          : globalStoreConfigurationMap.no[language]
      },
    },
  ]

  return (
    <>
      <div>
        <Typography className={classes.heading} variant="h4">
          {globalStoreConfigurationMap.configurationGlobalView[language]}
        </Typography>
      </div>
      <Card className={classes.card}>
        <DataGridTable
          rowHeight={56}
          loading={globalStoresIsLoading}
          components={{ Toolbar: Toolbar }}
          componentsProps={{
            toolbar: { storesSnapshot, formHasNoEdits, setFormHasNoEdits, setHasFormErrors, setGlobalStoresIsLoading },
          }}
          columns={columns}
          rows={Object.values(storesSnapshot).sort(moveDcUp)}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
        />
      </Card>
      <SnackbarBuilder
        contentText={globalStoresSnackbar.message}
        isOpen={globalStoresSnackbar.show}
        autoHideDuration={2000}
        handleClose={() =>
          dispatch(
            setGlobalStoresSnackbar({
              show: false,
              success: globalStoresSnackbar.success,
              message: globalStoresSnackbar.message,
            })
          )
        }
        severityLevel={globalStoresSnackbar.success ? 'success' : 'error'}
        location={{ vertical: 'top', horizontal: 'center' }}
      />
      <Popup
        isOpen={isPopupOpen}
        dialogTitle={globalStoreConfigurationMap.areYouSure[language]}
        dialogContentText={globalStoreConfigurationMap.unsavedChanges[language]}
        cancelButtonText={globalStoreConfigurationMap.stay[language]}
        submitButtonText={globalStoreConfigurationMap.leave[language]}
        onConfirmClick={() => {
          setisPopupOpen(false)
          setFormHasNoEdits(true)
          setPopupSubmit(true)
        }}
        onDiscardClick={() => setisPopupOpen(false)}
      />
    </>
  )
}
