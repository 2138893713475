const isDev = process.env.NODE_ENV === 'development'

/**
 * Local time zone string Mapper. Ex 'America/Toronto'
 */
const TIMEZONE = {
  MST: 'MST7MDT',
  MDT: 'MST7MDT',
  CST: 'CST6CDT',
  CDT: 'CST6CDT',
  PST: 'PST8PDT',
  PDT: 'PST8PDT',
  EST: 'America/Montreal',
}

/**
 * default timezone to EST if none present
 */
const DEFAULT_TIMEZONE = 'EST'

/**
 * default threshold time to 15:00 if none present
 */
const DEFAULT_THRESHOLD = '15:00'

/**
 * This number dictates how long can a person stay authenticated, in miliseconds. When
 * the time limit expires, the next time they get on the site, they will be logged out.
 *
 * As of now, the auth limit is 3 days.
 */
const TTL = 3 * 24 * 60 * 60 * 1000

/**
 * Only emails carrying domains from this array will be able to log into the Audit tool.
 */
const ALLOWED_DOMAINS = ['dynamite.ca']

const baseURL = isDev ? '' : process.env.REACT_APP_BACKEND_URL

const USER_GUIDE_URL = {
  en: 'https://docs.google.com/document/d/1PIUxM8O2JDo5f4KvsT7n-nnGGeN9iUJk1W9OSml6tDo/edit?usp=sharing',
  fr: 'https://docs.google.com/document/d/1CTUkRiqgvUgB2FTVkIqL6Pd9lZ_Z4M_e2sL6gEjWyR0/edit?usp=sharing',
}

/**
 * Uber order status filters
 */
const IN_PREPARATION_FILTER = {
  latestOrderStatus: ['ORDER_CREATED_IN_SFCC'],
}

const AWAITING_PICKUP_FILTER = {
  latestOrderStatus: ['SCHEDULED', 'WAITING_FOR_DRIVER_TO_ACCEPT', 'EN_ROUTE_TO_PICKUP', 'ARRIVED_AT_PICKUP'],
}

const OUT_FOR_DELIVERY_FILTER = {
  latestOrderStatus: ['EN_ROUTE_TO_DROPOFF', 'ARRIVED_AT_DROPOFF'],
}

/* Hidden until required in a future phase */
// const RETURN_FILTER = {
//   latestOrderStats: [],
// }

const COMPLETED_FILTER = {
  latestOrderStatus: ['COMPLETED'],
}
const CANCELLED_FILTER = {
  latestOrderStatus: ['FAILED'],
}
const STATUS_LIST = {
  ORDER_CREATED_IN_SFCC: 'IN_PREPARATION',
  SCHEDULED: 'AWAITING_PICKUP',
  WAITING_FOR_DRIVER_TO_ACCEPT: 'AWAITING_PICKUP',
  EN_ROUTE_TO_PICKUP: 'AWAITING_PICKUP',
  ARRIVED_AT_PICKUP: 'ARRIVED_AT_PICKUP',
  EN_ROUTE_TO_DROPOFF: 'OUT_FOR_DELIVERY',
  ARRIVED_AT_DROPOFF: 'ARRIVED_AT_DROPOFF',
  COMPLETED: 'COMPLETED',
  FAILED: 'CANCELLED',
  RETURN: 'RETURN',
}

// Timeout in Milliseconds
const TIMEOUT_GET_TODAY_DELIVERIES = process.env.TIMEOUT_GET_TODAY_DELIVERIES || 60000
const TIMEOUT_GET_GLOBAL_VIEW_DELIVERIES = process.env.TIMEOUT_GET_GLOBAL_VIEW_DELIVERIES || 60000

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

// Microsoft auth url
const REACT_APP_TENANT = process.env.REACT_APP_TENANT
const REACT_APP_AUTH_REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL
const REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
const REACT_APP_SCOPE = process.env.REACT_APP_SCOPE
const MICROSOFT_AUTH_BASE_URL = `https://login.microsoftonline.com/${REACT_APP_TENANT}/oauth2/v2.0/authorize`
const MICROSOFT_LOGIN_URL = `${MICROSOFT_AUTH_BASE_URL}?client_id=${REACT_APP_AUTH_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_AUTH_REDIRECT_URL}&response_mode=query&scope=${REACT_APP_SCOPE}&domain_hint=${REACT_APP_TENANT}&prompt=login`

const USER_ROLE = {
  ADMIN: 'ADMIN',
  DISTRICT_SUPERVISOR: 'DISTRICT_SUPERVISOR',
  MANAGER: 'MANAGER',
  EMPLOYEE: 'EMPLOYEE',
}
const LANGUAGE = {
  EN: 'en',
  FR: 'fr',
}

module.exports = {
  TIMEZONE,
  TTL,
  ALLOWED_DOMAINS,
  baseURL,
  USER_GUIDE_URL,
  IN_PREPARATION_FILTER,
  AWAITING_PICKUP_FILTER,
  OUT_FOR_DELIVERY_FILTER,
  //   RETURN_FILTER,
  COMPLETED_FILTER,
  CANCELLED_FILTER,
  STATUS_LIST,
  TIMEOUT_GET_TODAY_DELIVERIES,
  TIMEOUT_GET_GLOBAL_VIEW_DELIVERIES,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_TENANT,
  REACT_APP_AUTH_REDIRECT_URL,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_SCOPE,
  MICROSOFT_LOGIN_URL,
  USER_ROLE,
  LANGUAGE,
  DEFAULT_TIMEZONE,
  DEFAULT_THRESHOLD,
}
