import momentTz from 'moment-timezone'
import { checkTimeZone } from './general'
import { DEFAULT_TIMEZONE, LANGUAGE } from '../const'

/**
 *
 * @param {string} date
 * @param {string} storeTimeZone
 * @returns  newly zone formatted date and time set to selected store's timezone
 */
export const formatDateAndTime = (date, storeTimeZone = DEFAULT_TIMEZONE) =>
  date ? momentTz(date).tz(checkTimeZone(storeTimeZone)).format('yyyy/MM/DD HH:mm:ss') : ''

/**
 * @param {string} date Date
 * @returns {string} newly zone formatted time
 */
export const formatTime = (date, storeTimeZone = DEFAULT_TIMEZONE) =>
  date ? momentTz(date).tz(checkTimeZone(storeTimeZone)).format('HH:mm:ss') : ''

/**
 * @param {string} date Date
 * @returns {string} Start of the day in utc time zone - defaults to DEFAULT_TIMEZONE
 *
 */
export const formatDateToStartOfDay = (date, storeTimeZone = DEFAULT_TIMEZONE) =>
  date ? momentTz(date).tz(checkTimeZone(storeTimeZone)).startOf('day').toISOString() : ''

/**
 * @param {string} date Date
 * @returns {string} End of day in utc time zone - defaults to DEFAULT_TIMEZONE
 */
export const formatDateToEndOfDay = (date, storeTimeZone = DEFAULT_TIMEZONE) =>
  date ? momentTz(date).tz(checkTimeZone(storeTimeZone)).endOf('day').toISOString() : ''

/**
 *
 * @param {string} string
 * @returns string where first letter is capital followed by lowercase
 */
export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

/**
 *
 * @param {string} address
 * @returns {string} formats a full address
 */
export const fullAddress = address =>
  `${address.Address1}, ${capitalizeFirstLetter(address.City || '')}, ${address.State}, ${address.PostalCode}, ${
    address.Country === 'CA' ? 'Canada' : 'United States'
  }`

/**
 *
 * @param {string} title
 * @returns string for empty block when no trips correspond to a status
 */
export const blankCardDescription = (title, language = 'en') => {
  if (language === 'fr') {
    if (title.includes('compl') || title.includes('annul')) {
      return `Il n'y a pas de commandes ${title} aujourd'hui`
    }
    return `Il n'y a pas de commandes ${title}`
  }

  if (title.includes('complet') || title.includes('cancel')) {
    return `There are no ${title} deliveries today`
  }
  return `There are no deliveries ${title}`
}

export const formatOrderItems = orderDetails => {
  return orderDetails && orderDetails.orderItems && orderDetails.orderItems.length > 0
    ? orderDetails.orderItems.map(item => `${item.ItemWebDesc} (${item.QtyOrdered})`)
    : ['item list unavailable']
}

export const sanitizePostalCode = value => value.replace(/\s|\W/g, '').toUpperCase()

export const formatCountryPostalCode = (value, country) =>
  country === 'CA' ? formatPostalCode(value) : formatZipCode(value)

export const formatPostalCode = value => value.trim().replace(/([A-Za-z][0-9][A-Za-z])(A-Za-z]|\d)/gi, '$1 $2')

export const formatZipCode = value => value.trim().replace(/(\d{5})(\d)/gi, '$1-$2')

export const isNotOnlyWhitespace = value => /\S/.test(value)

export const priceOfRide = (language, customerCountry, costTrip = 10) =>
  language === LANGUAGE.EN ? `${customerCountry}$${costTrip}` : `${customerCountry} ${costTrip}$`
