import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import noResults from '../../assets/images/noResults.svg'
import { noResultsMap } from './reusableContent'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60px',
  },

  image: {
    width: '40%',
    paddingBottom: '2em',
  },
})

export const NoResults = () => {
  const classes = useStyles()
  const { language } = useSelector(state => state.auth)

  return (
    <Container className={classes.root}>
      <img className={classes.image} src={noResults} alt="No results" />
      <Typography variant="h4">{noResultsMap.noResultsTitle[language]}</Typography>
      <Typography variant="subtitle1">{noResultsMap.noResultsSubtitle[language]}</Typography>
    </Container>
  )
}
