import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { colors } from '../colors'
import { Add } from '@material-ui/icons'
import { setSelectedStore } from '../modules/auth/auth.redux'
import { List, ListItem, ListItemIcon, ListItemText, Divider, ListSubheader, Select } from '@material-ui/core'
import { leftSideMenuMap } from './layoutContent'

export const StoresDropDown = ({ stores, selectedStore }) => {
  const dispatch = useDispatch()
  return (
    <Fragment>
      <List>
        <Select
          variant="outlined"
          native
          value={selectedStore.id}
          onChange={e => dispatch(setSelectedStore(e.target.value))}
        >
          {stores.map(store => (
            <option value={store.id} key={store.id}>
              {store.id === 'DC'
                ? `${store.storeName} - ${store.id}`
                : `${store.mallName} - ${store.chainName} - ${store.id}`}
            </option>
          ))}
        </Select>
      </List>
    </Fragment>
  )
}

export const NewDelivery = ({ classes, isNewDeliveryPath, history, language }) => (
  <Fragment>
    <List>
      <ListItem
        button
        key={'New Delivery'}
        classes={{ selected: classes.newDeliverySelected }}
        style={{ backgroundColor: colors.black, color: colors.white, fontWeight: 'bold' }}
        selected={isNewDeliveryPath}
        onClick={() => history.push('/newDelivery')}
      >
        <ListItemIcon>
          <Add style={{ backgroundColor: 'none', color: isNewDeliveryPath ? colors.dimGrey : colors.white }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          classes={{ primary: classes.listItemText }}
          primary={leftSideMenuMap.newDelivery[language]}
        />
      </ListItem>
    </List>
  </Fragment>
)

export const MenuItemButton = ({
  label,
  icon: Icon,
  href,
  count,
  classes,
  currentLocation,
  buttonStyle,
  iconStyle,
}) => {
  const history = useHistory()
  return (
    <ListItem
      button
      key={label}
      classes={{ selected: classes.selectedItem }}
      style={buttonStyle}
      selected={href === history.location.pathname || href === currentLocation}
      onClick={() => history.push(href)}
    >
      {Icon ? (
        <ListItemIcon>
          <Icon style={iconStyle} />
        </ListItemIcon>
      ) : null}
      <ListItemText disableTypography classes={{ primary: classes.listItemText }} primary={label} />
      {count}
    </ListItem>
  )
}

export const StoreDisplay = ({ userInfo }) => (
  <Fragment>
    <List>
      {userInfo.stores[0].id === 'DC'
        ? `${userInfo.stores[0].storeName} - ${userInfo.stores[0].id}`
        : `${userInfo.stores[0].mallName} - ${userInfo.stores[0].chainName} - ${userInfo.stores[0].id}`}
    </List>
  </Fragment>
)

export const WelcomeDisplay = ({ userInfo }) => {
  const { language } = useSelector(state => state.auth)

  return (
    <>
      <Divider />
      <Fragment>
        <List>
          <ListSubheader component="div" id="nested-list-subheader">
            {`${leftSideMenuMap.welcome[language]}, ${userInfo.displayName}`}
          </ListSubheader>
        </List>
      </Fragment>
      <Divider />
    </>
  )
}
