import React, { Fragment, useEffect, useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Divider, Snackbar, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExitToApp, Today, History, Search, Close, Store, LocalPostOffice } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from '../modules/auth/auth.redux'
import { setSearchResultsSnackbar } from '../modules/searchResults/searchResults.redux'
import { SearchBar } from './searchBar'
import { displayCountOfFilteredItemsByOrderStatus } from '../utils/filter'
import { getSortedStores } from '../utils/sort'
import {
  IN_PREPARATION_FILTER,
  AWAITING_PICKUP_FILTER,
  OUT_FOR_DELIVERY_FILTER,
  //   RETURN_FILTER,
  COMPLETED_FILTER,
  CANCELLED_FILTER,
} from '../const'
import { colors } from '../colors'
import { StoresDropDown, NewDelivery, MenuItemButton, WelcomeDisplay, StoreDisplay } from './drawerItems'
import { layoutLabelMap, leftSideMenuMap } from './layoutContent'

const useStyles = makeStyles(theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    padding: 15,
  },
  selectedItem: {
    fontWeight: 'bold',
  },
  listItemText: {
    fontSize: '0.85rem',
  },
  newDeliverySelected: {
    backgroundColor: `${colors.lightGrey} !important`,
    color: `${colors.dimGrey} !important`,
    fontWeight: 'bold',
  },
}))

export const DrawerContent = () => {
  const classes = useStyles()
  const { userInfo, selectedStore, isLoggedIn, language } = useSelector(state => state.auth)
  const { searchResultsFound, showSearchResultsSnackbar, searchResultsSnackbarMsg } = useSelector(state => state.search)
  const { todaysDeliveries } = useSelector(state => state.today)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [currentLocation, setCurrentLocation] = useState()

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSearchResultsSnackbar(false))
  }

  useEffect(() => {
    if (searchResultsFound) {
      history.push('/pastDeliveries')
    }
  }, [searchResultsFound, history])

  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location])

  /**
   * You can add or modify the buttons on the sidebar with this array of sections.
   * A "section" is a group of sidebar buttons that is divided from other sections with
   * two thin lines at the top and bottom. This drawer section will only become visible
   * when the user is properly authenticated.
   *
   * A section can have a subheader. This subheader can be undefined if you don't wish
   * to add one for a particular section.
   *
   * To add an icon to a sidebar button, you can import icon components from
   * @material-ui/icons and add it to the "icon" property of a section item. You can go to
   * https://material-ui.com/components/material-icons/ for a catalog of Material Icons and
   * their component name.
   *
   * You can add an onClick or a href for each section item. The onClick will happen before the
   * href if you need to use both of them. If the onClick is a Promise, the href will be delayed
   * until the onClick is done.
   */

  const authenticatedDrawerSections = [
    {
      items: [
        {
          label: `${leftSideMenuMap.todaysDeliveries[language]}`,
          icon: Today,
          href: '/todaysDeliveries',
          count:
            [...todaysDeliveries.trips, ...todaysDeliveries.ordersInPreparation].length === 0
              ? '-'
              : [...todaysDeliveries.trips, ...todaysDeliveries.ordersInPreparation].length,
        },
        {
          label: `${leftSideMenuMap.inPreparation[language]}`,
          icon: 'div',
          href: '/inPreparation',
          count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.ordersInPreparation, IN_PREPARATION_FILTER),
        },
        {
          label: `${leftSideMenuMap.awaitingPickup[language]}`,
          icon: 'div',
          href: '/awaitingPickup',
          count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.trips, AWAITING_PICKUP_FILTER),
        },
        {
          label: `${leftSideMenuMap.outForDelivery[language]}`,
          icon: 'div',
          href: '/outForDelivery',
          count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.trips, OUT_FOR_DELIVERY_FILTER),
        },
        /* Disabled until needed for future phases */
        // {
        //   label: 'Return In Progress',
        //   icon: 'div',
        //   href: '/return',
        //   count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.trips, RETURN_FILTER),
        // },
        {
          label: `${leftSideMenuMap.cancelled[language]}`,
          icon: 'div',
          href: '/cancelled',
          count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.trips, CANCELLED_FILTER),
        },
        {
          label: `${leftSideMenuMap.completed[language]}`,
          icon: 'div',
          href: '/completed',
          count: displayCountOfFilteredItemsByOrderStatus(todaysDeliveries.trips, COMPLETED_FILTER),
        },
        {
          label: `${leftSideMenuMap.uberConnect[language]}`,
          icon: 'div',
          href: '/uberConnect',
        },
      ],
    },
    {
      items: [
        {
          label: `${leftSideMenuMap.pastDeliveries[language]}`,
          icon: History,
          href: '/pastDeliveries',
        },
      ],
    },
  ]

  const adminSection = [
    {
      label: `${leftSideMenuMap.deliveriesGlobalView[language]}`,
      icon: Today,
      href: '/globalDeliveries',
    },
    {
      label: `${leftSideMenuMap.storeConfiguration[language]}`,
      icon: Store,
      href: '/storeConfiguration',
    },
    {
      label: `${leftSideMenuMap.configurationGlobalView[language]}`,
      icon: Store,
      href: '/globalStoreConfiguration',
    },
    {
      label: `${leftSideMenuMap.postalCodeConfiguration[language]}`,
      icon: LocalPostOffice,
      href: '/postalCodeConfiguration',
    },
    {
      label: `${leftSideMenuMap.DSSConfiguration[language]}`,
      icon: Store,
      href: '/dssConfiguration',
    },
  ]

  const dssSection = [
    {
      label: `${leftSideMenuMap.deliveriesGlobalView[language]}`,
      icon: Today,
      href: '/globalDeliveries',
    },
    {
      label: `${leftSideMenuMap.storeConfiguration[language]}`,
      icon: Store,
      href: '/storeConfiguration',
    },
    {
      label: `${leftSideMenuMap.configurationGlobalView[language]}`,
      icon: Store,
      href: '/globalStoreConfiguration',
    },
  ]

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {isLoggedIn.cata({
        Yes: () => (
          <>
            {userInfo.role.cata({
              Admin: () => <StoresDropDown stores={getSortedStores(userInfo.stores)} selectedStore={selectedStore} />,
              DistrictSupervisor: () => (
                <StoresDropDown stores={getSortedStores(userInfo.stores)} selectedStore={selectedStore} />
              ),
              Manager: () => <StoreDisplay userInfo={userInfo} />,
              Employee: () => <StoreDisplay userInfo={userInfo} />,
              None: () => null,
            })}
            <WelcomeDisplay userInfo={userInfo} />
            {userInfo.role.cata({
              Admin: () => (
                <NewDelivery
                  classes={classes}
                  isNewDeliveryPath={currentLocation === '/newDelivery' || history.location.pathname === '/newDelivery'}
                  history={history}
                  language={language}
                />
              ),
              DistrictSupervisor: () => (
                <NewDelivery
                  classes={classes}
                  isNewDeliveryPath={currentLocation === '/newDelivery'}
                  history={history}
                  language={language}
                />
              ),
              Manager: () => (
                <NewDelivery
                  classes={classes}
                  isNewDeliveryPath={currentLocation === '/newDelivery'}
                  history={history}
                  language={language}
                />
              ),
              Employee: () => null,
              None: () => null,
            })}
            <Divider />
            <Fragment>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  <SearchBar label={layoutLabelMap.searchBarLabel[language]} />
                </ListItem>
              </List>
            </Fragment>
            <Divider />
            {authenticatedDrawerSections.map(({ items, subheader }, index) => (
              <Fragment key={subheader || index}>
                <List>
                  {items &&
                    items.map(({ label, icon, href, count }) => (
                      <MenuItemButton
                        key={label}
                        label={label}
                        icon={icon}
                        href={href}
                        classes={classes}
                        currentLocation={currentLocation}
                        count={count}
                      />
                    ))}
                </List>
                <Divider />
              </Fragment>
            ))}
            {userInfo.role.cata({
              Admin: () => (
                <>
                  <Fragment>
                    <List>
                      {adminSection.map(({ label, icon, href }, index) => (
                        <MenuItemButton
                          key={index}
                          label={label}
                          icon={icon}
                          href={href}
                          classes={classes}
                          currentLocation={currentLocation}
                        />
                      ))}
                    </List>
                  </Fragment>
                  <Divider />
                </>
              ),
              DistrictSupervisor: () => (
                <>
                  <Fragment>
                    <List>
                      {dssSection.map(({ label, icon, href }, index) => (
                        <MenuItemButton
                          key={index}
                          label={label}
                          icon={icon}
                          href={href}
                          classes={classes}
                          currentLocation={currentLocation}
                        />
                      ))}
                    </List>
                  </Fragment>
                  <Divider />
                </>
              ),
              Manager: () => (
                <>
                  <Fragment>
                    <List>
                      <MenuItemButton
                        label={layoutLabelMap.storeConfigLabel[language]}
                        icon={Store}
                        href={'/storeConfiguration'}
                        classes={{ selected: classes.selectedItem }}
                        currentLocation={currentLocation}
                      />
                    </List>
                  </Fragment>
                  <Divider />
                </>
              ),
              Employee: () => null,
              None: () => null,
            })}
            <Fragment>
              <List>
                <ListItem button classes={{ selected: classes.selectedItem }} onClick={() => dispatch(logout())}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    classes={{ primary: classes.listItemText }}
                    primary={`${leftSideMenuMap.logout[language]}`}
                  />
                </ListItem>
              </List>
            </Fragment>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={showSearchResultsSnackbar}
              autoHideDuration={5000}
              onClose={handleClose}
              message={searchResultsSnackbarMsg}
              action={
                <>
                  <Button color="secondary" size="small" onClick={handleClose}>
                    OK
                  </Button>
                  <IconButton
                    size="small"
                    aria-label={layoutLabelMap.closeLabel[language]}
                    color="inherit"
                    onClick={handleClose}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </>
        ),
        No: () => null,
      })}
    </div>
  )
}
