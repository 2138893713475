import React from 'react'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { getSearchResults } from '../modules/searchResults/searchResults.redux'

const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '3px',
        borderColor: 'black',
      },
    },
  },
})(TextField)

export const SearchBar = ({ label }) => {
  const dispatch = useDispatch()

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        const search = e.target[0].value.trim()
        if (!!search) {
          dispatch(getSearchResults({ search }))
        }
        e.target.reset()
      }}
    >
      <SearchTextField
        key={label}
        label={label}
        variant="outlined"
        size="small"
        InputLabelProps={{ style: { fontSize: 12 } }}
      />
    </form>
  )
}
