import React, { useState } from 'react'
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { DrawerContent } from './drawer'
import { USER_GUIDE_URL } from '../const'
import MenuIcon from '@material-ui/icons/Menu'
import HelpIcon from '@material-ui/icons/Help'
import { useSelector, useDispatch } from 'react-redux'
import { setLanguage } from '../modules/auth/auth.redux'
import { changeLanguage } from '../utils/general'
import { languageMap, layoutLabelMap } from './layoutContent'

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: 'black',
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100%',
  },
  title: {
    flexGrow: 1,
  },
}))

export const Layout = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [mobileOpen, setMobileOpen] = useState(false)
  const { language } = useSelector(state => state.auth)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const setLanguageCookie = language => {
    localStorage.setItem('oneDayDashboardLanguage', language)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={layoutLabelMap.openDrawerLabel[language] || ''}
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            OneDay Dashboard
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={event => {
              event && event.stopPropagation()
              const newLang = changeLanguage(language)
              dispatch(setLanguage(newLang))
              setLanguageCookie(newLang)
            }}
          >
            {(language && languageMap.opposingDisplay[language]) || ''}
          </Button>
          <Tooltip
            title={layoutLabelMap.userGuideTitle[language] || ''}
            aria-label={layoutLabelMap.userGuideLabel[language] || ''}
            arrow
          >
            <IconButton
              color="inherit"
              aria-label={layoutLabelMap.helpButtonLabel[language] || ''}
              href={USER_GUIDE_URL[language] || ''}
              target="_blank"
              className={classes.helpButton}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label={layoutLabelMap.mailboxLabel[language] || ''}>
        {mobileOpen ? (
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <DrawerContent />
            </Drawer>
          </Hidden>
        ) : null}
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}
