export const languageMap = {
  opposingDisplay: {
    en: 'fr',
    fr: 'en',
  },
}

export const layoutLabelMap = {
  userGuideTitle: {
    en: 'OneDay Dashboard User Guide',
    fr: `Guide de l'usager OneDay Dashboard`,
  },
  userGuideLabel: {
    en: 'OneDay Dashboard User Guide',
    fr: `Guide de l'usager OneDay Dashboard`,
  },
  helpButtonLabel: {
    en: 'help button',
    fr: `bouton d'aide`,
  },
  openDrawerLabel: {
    en: 'open drawer',
    fr: 'menu ouvert”',
  },
  mailboxLabel: {
    en: 'mailbox folders',
    fr: 'dossiers de boîte aux lettres',
  },
  searchBarLabel: {
    en: 'Search (by order number)',
    fr: `Recherche (par no de commande)`,
  },
  storeConfigLabel: {
    en: 'Store Configuration',
    fr: `Magasin - Configuration`,
  },
  closeLabel: {
    en: 'close',
    fr: 'fermez',
  },
}

export const leftSideMenuMap = {
  welcome: {
    en: `Welcome`,
    fr: `Bienvenue`,
  },
  newDelivery: {
    en: `New Delivery`,
    fr: `Nouvelle livraison`,
  },
  searchByOrderNum: {
    en: `Search (by order number)`,
    fr: `Recherche (par no de commande)`,
  },
  todaysDeliveries: {
    en: `Today's Deliveries`,
    fr: `Livraison du jour`,
  },
  inPreparation: {
    en: `In Preparation`,
    fr: `En préparation`,
  },
  awaitingPickup: {
    en: `Awaiting Pickup`,
    fr: `En attente de ramassage`,
  },
  outForDelivery: {
    en: `Out for Delivery`,
    fr: `Livraison en cours`,
  },
  cancelled: {
    en: `Cancelled`,
    fr: `Annulées`,
  },
  completed: {
    en: `Completed`,
    fr: `Complétées`,
  },
  uberConnect: {
    en: `Uber-Connect`,
    fr: `Uber-Connect`,
  },
  pastDeliveries: {
    en: `Past Deliveries`,
    fr: `Livraisons passées`,
  },
  deliveriesGlobalView: {
    en: `Deliveries - Global view`,
    fr: `Livraisons - Vue Globale`,
  },
  storeConfiguration: {
    en: `Store Configuration`,
    fr: `Magasin - Configuration`,
  },
  configurationGlobalView: {
    en: `Configuration - Global view`,
    fr: `Configuration - Vue Globale`,
  },
  postalCodeConfiguration: {
    en: `Postal Code Configuration`,
    fr: `Codes Postaux - Configuration`,
  },
  DSSConfiguration: {
    en: `DSS Configuration`,
    fr: `DSS - Configuration`,
  },
  logout: {
    en: `Logout`,
    fr: `Déconnection`,
  },
  completedDeliveriesGlobalView: {
    en: `NEW page: Completed Deliveries - Global View`,
    fr: `Livraison complétées - Vue Globale`,
  },
}
