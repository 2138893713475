import { createAction, handleActions } from 'redux-actions'

export const getSearchResults = createAction('SEARCH_RESULTS_GET_SEARCH_RESULTS')
export const setSearchResultsFound = createAction('SEARCH_RESULTS_SET_SEARCH_RESULTS_FOUND')
export const setSearchResultsSnackbar = createAction('SEARCH_RESULTS_SET_SEARCH_RESULTS_SNACKBAR')
export const setSearchResultsSnackbarMsg = createAction('SEARCH_RESULTS_SET_SEARCH_RESULTS_SNACKBAR_MSG')

const INITIAL_STATE = {
  searchResultsFound: null,
  showSearchResultsSnackbar: false,
  searchResultsSnackbarMsg: '',
}

export const getStoreIds = stores =>
  (stores || []).map(store => ({
    storeId: store.id,
  }))

export const searchResultsReducer = handleActions(
  {
    [setSearchResultsFound]: (state, { payload: searchResultsFound }) => ({
      ...state,
      searchResultsFound,
    }),
    [setSearchResultsSnackbar]: (state, { payload: showSearchResultsSnackbar }) => ({
      ...state,
      showSearchResultsSnackbar,
    }),
    [setSearchResultsSnackbarMsg]: (state, { payload: searchResultsSnackbarMsg }) => ({
      ...state,
      searchResultsSnackbarMsg,
    }),
  },
  INITIAL_STATE
)
