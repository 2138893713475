export const todaysDeliveriesTitleMap = {
  preparation: {
    en: 'In Preparation',
    fr: 'En Préparation',
  },
  awaitingPickup: {
    en: 'Awaiting pickup',
    fr: 'En attente de ramassage',
  },
  outForDelivery: {
    en: 'Out for Delivery',
    fr: 'Livraison en cours',
  },
  cancelled: { en: 'Cancelled', fr: 'Annulées' },
  completed: { en: 'Completed', fr: 'Complétées' },
}
