import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export const SnackbarBuilder = ({
  contentText,
  isOpen,
  handleClose,
  severityLevel,
  autoHideDuration,
  location = { vertical: 'top', horizontal: 'center' },
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={autoHideDuration || 6000} onClose={handleClose} anchorOrigin={location}>
        <Alert onClose={handleClose} severity={severityLevel}>
          {contentText}
        </Alert>
      </Snackbar>
    </div>
  )
}
