export const setSearchResultsMap = {
  snackbarNoOrder: {
    en: 'No Order was found with given Order ID',
    fr: `Aucune commande n'a été trouvée avec le numéro de commande donné`,
  },
  snackbarNoTrip: {
    en: 'No Trips were created for given Order ID',
    fr: `Aucune livraison a été créée avec le numéro de commande donné`,
  },
}
