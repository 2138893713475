import { tryLogin, loginSuccess, setLoginError, setLoading } from './auth.redux'
import { TTL } from '../../const'
import { of } from 'fluture'
import { loginUser } from './auth.io'

const tryLoginEpic = {
  type: tryLogin.toString(),
  do: ({ payload: response }) =>
    loginUser(response)
      .chain(({ data }) =>
        of([
          loginSuccess({
            userInfo: {
              ...data.userInfo,
              loggedInDate: new Date().toISOString(),
              expiryDate: new Date(Date.now() + TTL).toISOString(),
            },
            token: data.token,
            refreshToken: data.refreshToken,
          }),
        ])
      )
      .chainRej(
        error =>
          console.log(error) ||
          of([setLoginError({ status: error.response.status, error: error.response.data.error }), setLoading(false)])
      ),
}

export default [tryLoginEpic]
