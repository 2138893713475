export const storeConfigurationMap = {
  headers: {
    acceptSameDayOrders: {
      en: 'Accept Same Day orders:',
      fr: 'Accepter commandes Same Day',
    },
    uberStoreId: {
      en: 'Uber Store ID:',
      fr: 'Uber Store ID:',
    },
    radius: {
      en: 'Radius (km):',
      fr: 'Rayon (km):',
    },
    thresholdTime: {
      en: 'Threshold Time',
      fr: 'Heure limite',
    },
    pickupInstruction: {
      en: 'Pick Up Notes',
      fr: 'Ramassage - notes',
    },
    dropOffInstruction: {
      en: 'Drop Off Notes',
      fr: 'Livraison - notes',
    },
  },
  buttons: {
    submit: {
      en: 'Submit',
      fr: 'Soumettre',
    },
    cancel: {
      en: 'Cancel',
      fr: 'Annuler',
    },
  },
  fields: {
    thresholdLabels: {
      monday: {
        en: 'Monday',
        fr: 'Lundi'
      }, 
      tuesday: {
        en: 'Tuesday',
        fr: 'Mardi',
      },
      wednesday: {
        en: 'Wednesday',
        fr: 'Mercredi',
      },
      thursday: {
        en: 'Thursday',
        fr: 'Jeudi',
      },
      friday: {
        en: 'Friday',
        fr: 'Vendredi',
      },
      saturday: {
        en: 'Saturday',
        fr: 'Samedi',
      },
      sunday: {
        en: 'Sunday',
        fr: 'Dimanche',
      },
    },
    timeOptions: {
      '11:00': {
        displayValue: {
          en: '11:00 AM',
          fr: '11h00'
        }
      },
      '12:00': {
        displayValue: {
          en: '12:00 PM',
          fr: '12h00'
        }
      },
      '13:00': {
        displayValue: {
          en: '1:00 PM',
          fr: '13h00'
        }
      },
      '14:00': {
        displayValue: {
          en: '2:00 PM',
          fr: '14h00'
        }
      },
      '15:00': {
        displayValue: {
          en: '3:00 PM',
          fr: '15h00'
        }
      },
      '16:00': {
        displayValue: {
          en: '4:00 PM',
          fr: '16h00'
        }
      },
      '17:00': {
        displayValue: {
          en: '5:00 PM',
          fr: '17h00'
        }
      },
      '18:00': {
        displayValue: {
          en: '6:00 PM',
          fr: '18h00'
        }
      },
      '19:00': {
        displayValue: {
          en: '7:00 PM',
          fr: '19h00'
        }
      },
      '20:00': {
        displayValue: {
          en: '8:00 PM',
          fr: '20h00'
        }
      },
    },
  },
}

export const storeConfigurationDialog = {
  cancelDialog: {
    title: {
      en: 'Are you sure?',
      fr: 'Es-tu certain?',
    },
    text: {
      en: `There are unsaved changes on the page. Are you sure you want to leave?`,
      fr: 'Il y a des modifications non enregistrées sur la page. Êtes-vous sûr de vouloir quitter?',
    },
    disagree: {
      en: `Stay`,
      fr: 'Rester',
    },
    agree: {
      en: `Leave`,
      fr: 'Quitter',
    },
  },
  submitDialog: {
    text: {
      en: `By disabling the Same Day Activation flag, this store will not be eligible to receive anymore Same Day orders.`,
      fr: `En désactivant l'indicateur d'activation Same Day, ce magasin ne pourra plus recevoir de commandes Same Day`,
    },
    disagree: {
      en: `No, undo`,
      fr: 'Non, annuler',
    },
    agree: {
      en: `Yes, submit`,
      fr: 'Oui, soumettre',
    },
  },
  error: {
    text: {
      en: 'Error: Missing or invalid field',
      fr: 'Erreur : Champ manquant ou invalide',
    },
    storeRadiusError: {
      en: 'Number between 0 and 22 is required',
      fr: 'Un nombre compris entre 0 et 22 est requis',
    },
    uberStoreIDError: {
      en: `Store's uber identifying string required for activation`,
      fr: `L'identification uber du magasin est requise pour l'activation`,
    },
    pickupInstructionError: {
      en: `An error occured in the pick up notes`,
      fr: `Une erreur s'est produite dans les notes de ramassage`,
    },
    dropOffInstructionError: {
      en: `An error occured in the drop off notes`,
      fr: `Une erreur s'est produite dans les notes de livraison`,
    },
  },
  successUpdate: {
    text: {
      en: 'Store settings has been updated!',
      fr: 'Configuration du magasin mise à jour',
    },
  },
}

export const storeConfigurationSnackbarMap = {
  error: {
    en: `Failed to updated store configurations`,
    fr: `Erreur : n'a pas pu mettre à jour les configurations du magasin`,
  },
  success: {
    en: 'Updated store configurations',
    fr: 'Configuration du magasin mise à jour',
  },
}
