import { addPostalCode, removePostalCode } from './postalCodeConfiguration.io'
import { postPostalCode, deletePostalCode, setPostalCodeSnackBar } from './postalCodeConfiguration.redux'
import { updateReduxStores, setSelectedStore } from '../auth/auth.redux'
import { postalCodeSnackBarMessages } from './postalCodeConfigurationContent'
import { of } from 'fluture'

const postPostalCodeEpic = {
  type: postPostalCode.toString(),
  do: ({ payload: { postalCode, storeId } }, { auth }) =>
    addPostalCode(auth.token, postalCode, storeId)
      .chain(res =>
        of([
          updateReduxStores(res.data),
          setSelectedStore(res.data.id),
          setPostalCodeSnackBar({ success: true, message: postalCodeSnackBarMessages.added[auth.language], show: true }),
        ])
      )
      .chainRej(err => of(setPostalCodeSnackBar({ success: false, message: err.response.data, show: true }))),
}

const deletePostalCodeEpic = {
  type: deletePostalCode.toString(),
  do: ({ payload: { postalCode, storeId } }, { auth }) =>
    removePostalCode(auth.token, postalCode, storeId)
      .chain(res =>
        of([
          updateReduxStores(res.data),
          setSelectedStore(res.data.id),
          setPostalCodeSnackBar({ success: true, message: postalCodeSnackBarMessages.removed[auth.language], show: true }),
        ])
      )
      .chainRej(err => of(setPostalCodeSnackBar({ success: false, message: err.response.data, show: true }))),
}

export default [postPostalCodeEpic, deletePostalCodeEpic]
