import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'
import { sanitizePostalCode } from '../../utils/string'

export const addPostalCode = (token, postalCode, storeId) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/postExcludedPostalCode',
      baseURL,
      data: {
        postalCode: sanitizePostalCode(postalCode),
        storeId,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const removePostalCode = (token, postalCode, storeId) =>
  tryP(() =>
    axios({
      method: 'DELETE',
      url: '/deleteExcludedPostalCode',
      baseURL,
      data: {
        postalCode,
        storeId,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
