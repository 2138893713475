import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, Card, Typography, Button } from '@material-ui/core'
import { GridColDef, GridToolbar } from '@material-ui/data-grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  getDistrictSupervisors,
  setIsLoading,
  updateSupervisorList,
  setSnackbar,
  setIsButtonDisabled,
  isValidEntry,
} from './dssConfiguration.redux'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import { Popup } from '../reusables/popup'
import { DataGridTable } from '../reusables/dataGridTable'
import { colors } from '../../colors'
import { dssConfigurationMap } from './dssConfigurationContent'


const useStyles = makeStyles(() => ({
  heading: {
    paddingBottom: 40,
  },
  card: {
    width: '100%',
    height: 700,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  autocomplete: {
    width: '100%',
  },
  option: {
    '& .employee-id': {
      fontSize: '11px',
    },
  },
  button: {
    backgroundColor: colors.black,
    color: colors.white,
    width: 150,
    '&:hover': {
      backgroundColor: colors.black,
      color: colors.white,
    },
  },
  disabledButton: {
    backgroundColor: colors.fadedGrey,
  },
}))

const Toolbar = ({ storesSnapshot, isButtonDisabled }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { language } = useSelector(state => state.auth)

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <GridToolbar />
      <div style={{ padding: '10px' }}>
        <Button
          variant="contained"
          classes={{ disabled: classes.disabledButton, root: classes.button }}
          disabled={isButtonDisabled}
          onClick={() => {
            dispatch(setIsLoading(true))
            dispatch(updateSupervisorList(storesSnapshot))
          }}
        >
          {dssConfigurationMap.submit[language]}
        </Button>
      </div>
    </div>
  )
}

export const DSSConfiguration = () => {
  const classes = useStyles()
  const { stores } = useSelector(state => state.auth.userInfo)
  const [storesSnapshot, setStoresSnapshot] = useState({})
  const [formValuesSnapshot, setFormValuesSnapshot] = useState({})
  const [isPopupOpen, setisPopupOpen] = useState(false)
  const [navigation, setNavigation] = useState()
  const [popupSubmit, setPopupSubmit] = useState(false)
  const { supervisors, isLoading, snackbar, isButtonDisabled } = useSelector(state => state.districtSupervisors)
  const { language } = useSelector(state => state.auth)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getDistrictSupervisors({ manualTrigger: false }))
    dispatch(setIsLoading(true))
  }, [dispatch])

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (isButtonDisabled) {
        return true
      }
      setisPopupOpen(true)
      setNavigation(pathname)
      return false
    })

    if (isButtonDisabled && popupSubmit) {
      history.push(navigation)
    }

    return unblock
  }, [isButtonDisabled, history, popupSubmit, navigation])

  useEffect(() => {
    const storeMap = stores.reduce((acc, store) => {
      acc[store.id] = {
        id: store.id,
        mallName: store.mallName || store.storeName,
        banner: store.chainName,
        storeNumber: store.id,
        province: store.state,
        city: store.city,
        supervisor: formValuesSnapshot[store.id] || supervisors[store.districtSupervisorId],
      }
      return acc
    }, {})

    setStoresSnapshot(storeMap)
  }, [supervisors, stores, isButtonDisabled, formValuesSnapshot])

  const columns: GridColDef[] = [
    { field: 'mallName', headerName: dssConfigurationMap.mallName[language], flex: 1, disableColumnMenu: true },
    { field: 'banner', headerName: dssConfigurationMap.banner[language], flex: 0.5, disableColumnMenu: true },
    { field: 'storeNumber', headerName: dssConfigurationMap.storeNum[language], flex: 0.5, disableColumnMenu: true },
    { field: 'province', headerName: dssConfigurationMap.province[language], flex: 0.5, disableColumnMenu: true },
    { field: 'city', headerName: dssConfigurationMap.city[language], flex: 0.5, disableColumnMenu: true },
    {
      field: 'supervisor',
      headerName: 'DSS',
      flex: 1,
      valueGetter: ({ row }) => (row.supervisor && row.supervisor.displayName) || '',
      renderCell: ({ row, value }) => {
        return (
          <>
            <Autocomplete
              options={Object.values(supervisors)}
              classes={{
                root: classes.autocomplete,
                option: classes.option,
              }}
              value={row.supervisor || ''}
              getOptionLabel={option => option.displayName || ''}
              getOptionSelected={(option, value) => isValidEntry(option, value)}
              renderOption={option => (
                <>
                  <div>
                    {option.displayName}
                    <div className={'employee-id'}>{option.id}</div>
                  </div>
                </>
              )}
              onChange={(e, supervisor) => {
                dispatch(setIsButtonDisabled(false))
                setFormValuesSnapshot({
                  ...formValuesSnapshot,
                  [row.id]: {
                    ...supervisor,
                  },
                })
                setStoresSnapshot({
                  ...storesSnapshot,
                  [row.id]: {
                    ...storesSnapshot[row.id],
                    supervisor,
                  },
                })
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </>
        )
      },
      filterable: true,
      disableColumnMenu: true,
    },
  ]
  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.heading} variant="h4">
          {dssConfigurationMap.dssStoreAssignation[language]}
        </Typography>
        <Button
          variant="contained"
          style={{ height: '40px', background: colors.lightGrey }}
          onClick={() => {
            dispatch(getDistrictSupervisors({ manualTrigger: true }))
            dispatch(setIsLoading(true))
          }}
        >
          {dssConfigurationMap.getLatestDssList[language]}
        </Button>
      </div>
      <Card className={classes.card}>
        <DataGridTable
          components={{ Toolbar: Toolbar }}
          componentsProps={{ toolbar: { storesSnapshot, isButtonDisabled } }}
          loading={isLoading}
          columns={columns}
          rows={Object.values(storesSnapshot)}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
        />
        <SnackbarBuilder
          contentText={snackbar.message}
          isOpen={snackbar.show}
          autoHideDuration={2000}
          handleClose={() =>
            dispatch(setSnackbar({ show: false, success: snackbar.success, message: snackbar.message }))
          }
          severityLevel={snackbar.success ? 'success' : 'error'}
          location={{ vertical: 'top', horizontal: 'center' }}
        />
      </Card>
      <Popup
        isOpen={isPopupOpen}
        dialogTitle={dssConfigurationMap.areYouSure[language]}
        dialogContentText={dssConfigurationMap.unsavedChanges[language]}
        cancelButtonText={dssConfigurationMap.stay[language]}
        submitButtonText={dssConfigurationMap.leave[language]}
        onConfirmClick={() => {
          setisPopupOpen(false)
          setPopupSubmit(true)
          dispatch(setIsButtonDisabled(true))
        }}
        onDiscardClick={() => setisPopupOpen(false)}
      />
    </>
  )
}
