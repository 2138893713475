import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  makeStyles,
  Container,
  Card,
  Typography,
  TextField,
  Switch,
  Select,
  MenuItem,
  Grid,
  Button,
} from '@material-ui/core'
import { DialogBuilder } from '../reusables/dialogBuilder'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import { storeConfigurationMap, storeConfigurationDialog } from './storeConfigurationContent'
import { initialFormValues } from '../newDelivery/newDeliveryContent'
import { colors } from '../../colors'
import { onUpdateStoreConfiguration } from '../auth/auth.redux'
import { validateStoreConfigFields } from '../../utils/validation'
import { isNotOnlyWhitespace } from '../../utils/string'
import { setStoreConfigThresholdTimes } from '../../utils/general'
import { Role } from '../../utils/types'
import { Popup } from '../reusables/popup'
import { DEFAULT_THRESHOLD } from '../../const'

const useStyles = makeStyles(theme => ({
  heading: {
    paddingBottom: 40,
  },
  greenSwitch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.green,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.fadedGrey,
      },
    },
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
    '& #uberStoreIdInput': {
      width: 300,
    },
    '& #storeRadiusInput': {},
    '& #pickupInstructionInput': {
      width: 300,
      padding: 0,
    },
    '& #dropOffInstructionInput': {
      width: 300,
      padding: 0,
    },
  },
  formRoot: {
    margin: '20px 80px',
  },
  gridRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  dayItems: {
    display: 'flex',
    alignItems: 'center',
  },
  dayDropDown: {
    '& .MuiOutlinedInput-input': {
      padding: '15px 20px 15px 15px',
      width: 80,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 30,
    '& #cancelButton': {
      padding: '10px 50px',
      float: 'right',
      marginRight: 20,
      borderRadius: 5,
    },
    '& #submitButton': {
      padding: '10px 50px',
      float: 'left',
      marginLeft: 20,
      color: colors.white,
      borderRadius: 5,
    },
  },
}))

const ThresholdGrid = ({ language, sameDayThresholdTimeEst, setIsModifiedContent, storeConfigSelectedStore, setStoreConfigSelectedStore, isDisabled }) => {
  const classes = useStyles()

  return (
    <>
    {Object.keys(storeConfigurationMap.fields.thresholdLabels).map((day, index) => {

        return (
          <Grid container key={day + index} className={classes.dayItems} spacing={2}>
            <Grid item xs={4}>
              <Typography>{storeConfigurationMap.fields.thresholdLabels[day][language]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select className={classes.dayDropDown} variant="outlined"
                      disabled={isDisabled}
                      defaultValue={DEFAULT_THRESHOLD}
                      name={storeConfigurationMap.fields.thresholdLabels[day][language]}
                      value={sameDayThresholdTimeEst && sameDayThresholdTimeEst[day] || DEFAULT_THRESHOLD} 
                      onChange={e => {
                        setStoreConfigSelectedStore({
                           ...storeConfigSelectedStore,
                           sameDayThresholdTimeEst: {
                              ...storeConfigSelectedStore.sameDayThresholdTimeEst,
                              [day]: e.target.value
                            }})
                        setIsModifiedContent(true);
                     }}
                    >
                {Object.keys(storeConfigurationMap.fields.timeOptions).map((time, key) => {
                    return (
                        <MenuItem key={time + key} value={time}>
                          {storeConfigurationMap.fields.timeOptions[time].displayValue[language]}
                        </MenuItem>
                      )
                    })}
              </Select>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export const StoreConfiguration = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { selectedStore, userInfo, language } = useSelector(state => state.auth)

  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false)
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false)
  const [storeConfigSelectedStore, setStoreConfigSelectedStore] = useState(selectedStore)
  const [isModifiedContent, setIsModifiedContent] = useState(false)
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false)
  const [isPopupOpen, setisPopupOpen] = useState(false)
  const [navigation, setNavigation] = useState()
  const [popupSubmit, setPopupSubmit] = useState(false)

  useEffect(() => {
    setStoreConfigSelectedStore({
      ...selectedStore,
      uberStoreIDError: false,
      storeRadiusError: false,
      pickupInstruction: selectedStore.pickupInstruction
        ? selectedStore.pickupInstruction
        : initialFormValues.pickupInstruction.value,
      dropoffInstruction: selectedStore.dropoffInstruction
        ? selectedStore.dropoffInstruction
        : initialFormValues.dropoffInstruction.value,
        sameDayThresholdTimeEst: setStoreConfigThresholdTimes(storeConfigurationMap.fields.thresholdLabels, selectedStore)
    })
  }, [selectedStore])

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (isModifiedContent) {
        // warn user if page has been modified
        setisPopupOpen(true)
        setNavigation(pathname)
        return false
      }
      return true
    })

    if (popupSubmit) {
      history.push(navigation)
    }

    return unblock
  }, [isModifiedContent, history, popupSubmit, navigation])

  const handleChange = (target, newValue) => {
    if (target !== 'storeRadius' || (newValue >= 0 && newValue <= 22)) {
      setStoreConfigSelectedStore({
        ...storeConfigSelectedStore,
        [target]: newValue,
      })
      setIsModifiedContent(true)
    }
  }

  // when data is saved :
  // Close dialog box, modified content is false, and show success message
  const setSucessState = () => {
    setIsSubmitDialogOpen(false)
    setIsModifiedContent(false)
    setSuccessSnackBarOpen(true)
  }

  const requestUpdateStoreConfiguration = (storeConfigSelectedStore, userConfirmedUpdateIntent = false) => {
    if (!validateStoreConfigFields(storeConfigSelectedStore, setStoreConfigSelectedStore)) {
      setErrorSnackBarOpen(true)
    } else {
      if (
        !storeConfigSelectedStore.sameDayActivation &&
        selectedStore.sameDayActivation &&
        !userConfirmedUpdateIntent
      ) {
        setIsSubmitDialogOpen(true)
      } else {
        setSucessState()

        dispatch(
          onUpdateStoreConfiguration({
            storeId: storeConfigSelectedStore.id,
            uberStoreID: storeConfigSelectedStore.uberStoreID,
            sameDayActivation: storeConfigSelectedStore.sameDayActivation,
            storeRadius: !!storeConfigSelectedStore.storeRadius
              ? storeConfigSelectedStore.storeRadius.toString()
              : null,
            pickupInstruction:
              storeConfigSelectedStore.pickupInstruction &&
              isNotOnlyWhitespace(storeConfigSelectedStore.pickupInstruction)
                ? storeConfigSelectedStore.pickupInstruction
                : initialFormValues.pickupInstruction.value,
            dropoffInstruction:
              storeConfigSelectedStore.dropoffInstruction &&
              isNotOnlyWhitespace(storeConfigSelectedStore.dropoffInstruction)
                ? storeConfigSelectedStore.dropoffInstruction
                : initialFormValues.dropoffInstruction.value,
            sameDayThresholdTimeEst: storeConfigSelectedStore.sameDayThresholdTimeEst
          })
        )
      }
    }
  }

  return (
    <>
      <Container>
        <Typography className={classes.heading} variant="h4">
          {`${storeConfigSelectedStore.id === 'DC' ? 'Distribution Centre' : storeConfigSelectedStore.id} - ${
            storeConfigSelectedStore.chainName
          } ${!!storeConfigSelectedStore.mallName ? storeConfigSelectedStore.mallName : ''}`}
        </Typography>
        <Card className={classes.formRoot}>
          <form>
            <Grid container spacing={3} className={classes.gridRoot}>
              <Grid container item xs={12} className={classes.gridItem}>
                <Grid item xs={4}>
                  <Typography>{storeConfigurationMap.headers.acceptSameDayOrders[language]}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Switch
                    className={classes.greenSwitch}
                    edge="start"
                    name="sameDayActivation"
                    checked={storeConfigSelectedStore.sameDayActivation}
                    onChange={e => handleChange(e.target.name, e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridItem}>
                <Grid item xs={4}>
                  <Typography>{storeConfigurationMap.headers.uberStoreId[language]}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    id="uberStoreIdInput"
                    variant="outlined"
                    name="uberStoreID"
                    className={classes.input}
                    error={storeConfigSelectedStore.uberStoreIDError}
                    helperText={
                      storeConfigSelectedStore.uberStoreIDError
                        ? storeConfigurationDialog.error.uberStoreIDError[language]
                        : ''
                    }
                    disabled={!Role.Admin.is(userInfo.role)}
                    value={storeConfigSelectedStore.uberStoreID ? storeConfigSelectedStore.uberStoreID : ''}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridItem}>
                <Grid item xs={4}>
                  <Typography>{storeConfigurationMap.headers.radius[language]}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="storeRadiusInput"
                    name="storeRadius"
                    variant="outlined"
                    className={classes.input}
                    error={storeConfigSelectedStore.storeRadiusError}
                    helperText={
                      storeConfigSelectedStore.storeRadiusError
                        ? storeConfigurationDialog.error.storeRadiusError[language]
                        : ''
                    }
                    disabled={!Role.Admin.is(userInfo.role)}
                    value={
                      storeConfigSelectedStore.storeRadius || !isNaN(storeConfigSelectedStore.storeRadius)
                        ? storeConfigSelectedStore.storeRadius
                        : ''
                    }
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridItem} id="thresholdTime">
                <Grid item xs={4}>
                  <Typography>{`${storeConfigurationMap.headers.thresholdTime[language]} (${storeConfigSelectedStore.timezone})`}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <ThresholdGrid  language={language} 
                                  sameDayThresholdTimeEst={storeConfigSelectedStore.sameDayThresholdTimeEst}
                                  setIsModifiedContent={setIsModifiedContent}
                                  storeConfigSelectedStore={storeConfigSelectedStore}
                                  setStoreConfigSelectedStore={setStoreConfigSelectedStore}
                                  isDisabled={!Role.Admin.is(userInfo.role) && !Role.DistrictSupervisor.is(userInfo.role) && !Role.Manager.is(userInfo.role)}
                              />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridItem}>
                <Grid item xs={4}>
                  <Typography>{storeConfigurationMap.headers.pickupInstruction[language]}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="pickupInstructionInput"
                    variant="outlined"
                    name="pickupInstruction"
                    className={classes.input}
                    error={storeConfigSelectedStore.pickupInstructionError}
                    helperText={
                      storeConfigSelectedStore.pickupInstructionError
                        ? storeConfigurationDialog.error.pickupInstructionError[language]
                        : ''
                    }
                    disabled={!Role.Admin.is(userInfo.role) && !Role.DistrictSupervisor.is(userInfo.role) && !Role.Manager.is(userInfo.role)}
                    value={storeConfigSelectedStore.pickupInstruction ? storeConfigSelectedStore.pickupInstruction : ''}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    required
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridItem}>
                <Grid item xs={4}>
                  <Typography>{storeConfigurationMap.headers.dropOffInstruction[language]}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="dropOffInstructionInput"
                    variant="outlined"
                    name="dropoffInstruction"
                    className={classes.input}
                    error={storeConfigSelectedStore.dropOffInstructionError}
                    helperText={
                      storeConfigSelectedStore.dropOffInstructionError
                        ? storeConfigurationDialog.error.dropOffInstructionError[language]
                        : ''
                    }
                    disabled={!Role.Admin.is(userInfo.role) && !Role.DistrictSupervisor.is(userInfo.role) && !Role.Manager.is(userInfo.role)}
                    value={
                      storeConfigSelectedStore.dropoffInstruction ? storeConfigSelectedStore.dropoffInstruction : ''
                    }
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    required
                    multiline
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.buttonsContainer}>
                <Grid item xs={4}>
                  <Button variant="contained" id="cancelButton" onClick={() => history.push('/todaysDeliveries')}>
                    {storeConfigurationMap.buttons.cancel[language]}
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    id="submitButton"
                    color="primary"
                    disabled={!isModifiedContent}
                    onClick={() => requestUpdateStoreConfiguration(storeConfigSelectedStore, false)}
                  >
                    {storeConfigurationMap.buttons.submit[language]}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>

      <DialogBuilder
        content={{
          text: storeConfigurationDialog.submitDialog.text[language],
          disagree: storeConfigurationDialog.submitDialog.disagree[language],
          agree: storeConfigurationDialog.submitDialog.agree[language],
        }}
        open={isSubmitDialogOpen}
        onDisagree={() => setIsSubmitDialogOpen(false)}
        onAgree={() => requestUpdateStoreConfiguration(storeConfigSelectedStore, true) && setIsSubmitDialogOpen(false)}
      />
      <SnackbarBuilder
        contentText={storeConfigurationDialog.error.text[language]}
        isOpen={errorSnackBarOpen}
        handleClose={() => setErrorSnackBarOpen(false)}
        severityLevel={'error'}
        location={{ vertical: 'bottom', horizontal: 'center' }}
      />
      {/* Successful snack */}
      <SnackbarBuilder
        contentText={storeConfigurationDialog.successUpdate.text[language]}
        isOpen={successSnackBarOpen}
        autoHideDuration={1000}
        handleClose={() => setSuccessSnackBarOpen(false)}
        severityLevel={'success'}
        location={{ vertical: 'top', horizontal: 'center' }}
      />
      <Popup
        isOpen={isPopupOpen}
        dialogTitle={storeConfigurationDialog.cancelDialog.title[language]}
        dialogContentText={storeConfigurationDialog.cancelDialog.text[language]}
        cancelButtonText={storeConfigurationDialog.cancelDialog.disagree[language]}
        submitButtonText={storeConfigurationDialog.cancelDialog.agree[language]}
        onConfirmClick={() => {
          setisPopupOpen(false)
          setPopupSubmit(true)
          setIsModifiedContent(false)
        }}
        onDiscardClick={() => setisPopupOpen(false)}
      />
    </>
  )
}
