import { createAction, handleActions } from 'redux-actions'

export const onGetStoresWithTodaysDeliveries = createAction('GLOBAL_VIEW_ON_GET_STORES_WITH_TODAYS_DELIVERIES')
export const setLoading = createAction('GLOBAL_VIEW_SET_LOADING')
export const timeoutGetStoresWithTodaysDeliveries = createAction('GLOBAL_VIEW_TIMEOUT')
export const getStoresWithTodaysDeliveries = createAction('GLOBAL_VIEW_GET_SAMEDAY_STORES_WITH_TODAYS_DELIVERIES')
export const setStoresWithTodaysDeliveries = createAction('GLOBAL_VIEW_SET_SAMEDAY_STORES_WITH_TODAYS_DELIVERIES')

const INITIAL_STATE = {
  storesWithTodaysDeliveries: [],
  isLoading: false,
}

export const globalDeliveriesReducer = handleActions(
  {
    [setStoresWithTodaysDeliveries]: (state, { payload }) => ({
      ...state,
      storesWithTodaysDeliveries: payload,
      isLoading: false,
    }),
    [setLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
  },
  INITIAL_STATE
)
