import React, { useEffect } from 'react'
import { Layout } from './layout'
import { Routes } from './router'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { onGetTodaysDeliveries } from './modules/todaysDeliveries/todaysDeliveries.redux'
import { onGetStoresWithTodaysDeliveries } from './modules/globalDeliveries/globalDeliveries.redux'
import { UserLoggedIn, Role } from './utils/types'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
})

const App = () => {
  const dispatch = useDispatch()
  const { userInfo, isLoggedIn } = useSelector(state => state.auth)

  useEffect(() => {
    if (UserLoggedIn.Yes.is(isLoggedIn)) {
      dispatch(onGetTodaysDeliveries())

      if (Role.Admin.is(userInfo.role) || Role.DistrictSupervisor.is(userInfo.role)) {
        dispatch(onGetStoresWithTodaysDeliveries())
      }
    }
  }, [dispatch, isLoggedIn])

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Routes role={userInfo.role} isLoggedIn={isLoggedIn} />
      </Layout>
    </ThemeProvider>
  )
}

export default App
