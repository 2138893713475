import { getOrders, setOrders, setPastSearchResultsSnackbar } from './pastDeliveries.redux'
import { fetchOrders } from './pastDeliveries.io'
import { formatDateToStartOfDay, formatDateToEndOfDay } from '../../utils/string'

const getOrdersEpic = {
  type: getOrders.toString(),
  do: ({ payload: { fromDate, toDate, selectedStore } }, { auth }) => {
    return fetchOrders(
      auth.token,
      fromDate ? formatDateToStartOfDay(fromDate, selectedStore.timezone) : null,
      toDate ? formatDateToEndOfDay(toDate, selectedStore.timezone) : null,
      selectedStore.id
    ).map(res =>
      res.data.length === 0 ? [setPastSearchResultsSnackbar(true), setOrders(res.data)] : setOrders(res.data)
    )
  },
}

export default [getOrdersEpic]
