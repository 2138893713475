import React from 'react'
import { useSelector } from 'react-redux'
import { LoadingScreen } from '../../loading/loading'
import { makeStyles, Grid } from '@material-ui/core'
import { filterItemsByOrderStatus } from '../../utils/filter'
import { OrderCards } from '../orderCards/orderCards'
import { CancelTripDialog } from '../cancelTrip/cancelTripDialog'
import { todaysDeliveriesTitleMap } from './todaysDeliveriesContent'
import {
  IN_PREPARATION_FILTER,
  AWAITING_PICKUP_FILTER,
  OUT_FOR_DELIVERY_FILTER,
  //   RETURN_FILTER,
  COMPLETED_FILTER,
  CANCELLED_FILTER,
} from '../../const'

const useStyles = makeStyles(theme => ({
  allOrderCards: {
    width: '100%',
    display: 'flex',
    'align-items': 'center',
    'flex-direction': 'column',
  },
}))

export const TodaysDeliveries = () => {
  const { todaysDeliveries, isLoading } = useSelector(state => state.today)
  const { language } = useSelector(state => state.auth)
  const classes = useStyles()

  const preparation = filterItemsByOrderStatus(todaysDeliveries.ordersInPreparation, IN_PREPARATION_FILTER)
  const awaitingPickup = filterItemsByOrderStatus(todaysDeliveries.trips, AWAITING_PICKUP_FILTER)
  const outForDelivery = filterItemsByOrderStatus(todaysDeliveries.trips, OUT_FOR_DELIVERY_FILTER)
  //   const returnInProgress = filterItemsByOrderStatus(todaysDeliveries.trips, RETURN_FILTER)
  const completed = filterItemsByOrderStatus(todaysDeliveries.trips, COMPLETED_FILTER)
  const cancelled = filterItemsByOrderStatus(todaysDeliveries.trips, CANCELLED_FILTER)

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container alignItems="center" justify="center">
          <div className={classes.allOrderCards}>
            <OrderCards
              data={preparation}
              title={todaysDeliveriesTitleMap.preparation[language]}
              orderBy={'updatedAt'}
            />
            <OrderCards
              data={awaitingPickup}
              title={todaysDeliveriesTitleMap.awaitingPickup[language]}
              orderBy={'updatedAt'}
            />
            <OrderCards
              data={outForDelivery}
              title={todaysDeliveriesTitleMap.outForDelivery[language]}
              orderBy={'updatedAt'}
            />
            {/* <OrderCards data={returnInProgress} title={`Return in progress`} orderBy={'updatedAt'}/> */}
            <OrderCards data={cancelled} title={todaysDeliveriesTitleMap.cancelled[language]} orderBy={'updatedAt'} />
            <OrderCards data={completed} title={todaysDeliveriesTitleMap.completed[language]} orderBy={'updatedAt'} />
          </div>
        </Grid>
      )}
      <CancelTripDialog />
    </>
  )
}
