export const pastDeliveriesMap = {
  title: {
    en: 'Past Deliveries',
    fr: 'Livraisons passées',
  },
  dateTo: {
    en: 'Date to',
    fr: 'Date à',
  },
  dateFrom: {
    en: 'Date from',
    fr: 'Date au',
  },
  search: {
    en: 'Search',
    fr: 'Recherche',
  },
  noResult: {
    en: 'Your search returned no result.',
    fr: 'Votre recherche n\'a donné aucun résultat.'
  }
}
