import { createAction, handleActions } from 'redux-actions'

export const getTodaysDeliveries = createAction('TODAYS_DELIVERIES_GET_TODAYS_DELIVERIES')
export const setTodaysDeliveries = createAction('TODAYS_DELIVERIES_SET_TODAYS_DELIVERIES')
export const onGetTodaysDeliveries = createAction('TODAYS_DELIVERIES_ON_GET_TODAYS_DELIVERIES')
export const setLoading = createAction('TODAYS_DELIVERIES_SET_LOADING')
export const timeoutGetTodaysDeliveries = createAction('TODAYS_DELIVERIES_TIMEOUT')

//Actions for Cancelling a trip
export const setOpenCancelPopup = createAction('TODAYS_DELIVERIES_SET_OPEN_CANCEL_POPUP')
export const setTripIdToCancel = createAction('TODAYS_DELIVERIES_SET_TRIP_ID_TO_CANCEL')
export const cancelTrip = createAction('TODAYS_DELIVERIES_CANCEL_SAME_DAY_TRIP')
export const setCancelledSnackBar = createAction('TODAYS_DELIVERIES_SET_CANCELLED_SNACKBAR')
export const setRedirectToAwaitingPickup = createAction('TODAYS_DELIVERIES_SET_REDIRECT_TO_AWAITING_PICKUP')

//Actions for Creating New Delivery
export const getOrderData = createAction('TODAYS_DELIVERIES_GET_ORDER_DATA')
export const setOrderData = createAction('TODAYS_DELIVERIES_SET_ORDER_DATA')
export const setDisableNewDeliveryButtons = createAction('TODAYS_DELIVERIES_DISABLE_NEW_DELIVERY_BUTTONS')
export const setIsOrderIdDisabled = createAction('TODAYS_DELIVERIES_DISABLE_ORDER_ID_FIELD')
export const createUberDelivery = createAction('TODAYS_DELIVERIES_CREATE_UBER_DELIVERY')
export const setCreateDeliveryStatus = createAction('TODAYS_DELIVERIES_SET_NEW_DELIVERY')
export const setRedirectToTodaysDeliveries = createAction('TODAYS_DELIVERIES_SET_REDIRECT_TO_TODAYS_DELIVERIES')
export const setReminderPopup = createAction('TODAYS_DELIVERIES_SET_REMINDER_POPUP')

const INITIAL_STATE = {
  todaysDeliveries: {
    trips: [],
    ordersInPreparation: [],
  },
  isLoading: false,
  openCancelPopup: false,
  tripIdToCancel: '',
  redirectToAwaitingPickup: false,
  cancelledSnackBar: {
    success: false,
    message: '',
    show: false,
  },
  orderData: {},
  disableNewDeliveryButtons: false,
  isOrderIdDisabled: false,
  createDeliveryStatus: {
    success: false,
    showSnackBar: false,
  },
  redirectToTodaysDeliveries: false,
  isReminderPopupOpen: false,
}

export const todaysDeliveriesReducer = handleActions(
  {
    [setTodaysDeliveries]: (state, { payload: todaysDeliveries }) => ({
      ...state,
      todaysDeliveries: {
        trips: todaysDeliveries.trips || [],
        ordersInPreparation: todaysDeliveries.ordersInPreparation || [],
      },
      isLoading: false,
    }),
    [setLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    [setOpenCancelPopup]: (state, { payload }) => ({
      ...state,
      openCancelPopup: payload,
    }),
    [setTripIdToCancel]: (state, { payload }) => ({
      ...state,
      tripIdToCancel: payload,
    }),
    [setRedirectToAwaitingPickup]: (state, { payload }) => ({
      ...state,
      redirectToAwaitingPickup: payload,
    }),
    [setCancelledSnackBar]: (state, { payload }) => ({
      ...state,
      cancelledSnackBar: {
        success: payload.success,
        message: payload.message,
        show: payload.show,
      },
    }),
    [setOrderData]: (state, { payload }) => ({
      ...state,
      orderData: payload,
    }),
    [setDisableNewDeliveryButtons]: (state, { payload }) => ({
      ...state,
      disableNewDeliveryButtons: payload,
    }),
    [setIsOrderIdDisabled]: (state, { payload }) => ({
      ...state,
      isOrderIdDisabled: payload,
    }),
    [setCreateDeliveryStatus]: (state, { payload }) => ({
      ...state,
      createDeliveryStatus: {
        success: payload.success,
        showSnackBar: payload.showSnackBar,
      },
    }),
    [setRedirectToTodaysDeliveries]: (state, { payload }) => ({
      ...state,
      redirectToTodaysDeliveries: payload,
    }),
    [setReminderPopup]: (state, { payload }) => ({
      ...state,
      isReminderPopupOpen: payload,
    }),
  },
  INITIAL_STATE
)
