import { updateStoreConfiguration, bulkUpdateStoreConfiguration, fetchAllStores } from './storeConfiguration.io'
import { updateReduxStores, setSelectedStore } from '../auth/auth.redux'
import { onUpdateStoreConfiguration, onUpdateBulkStoreConfiguration } from '../auth/auth.redux'
import { getAllSameDayStores, setGlobalStoresSnackbar, setGlobalStoresIsLoading } from './storeConfiguration.redux'
import { of } from 'fluture'
import { storeConfigurationSnackbarMap } from './storeConfigurationContent'

const onUpdateStoreConfigurationEpic = {
  type: onUpdateStoreConfiguration.toString(),
  do: ({ payload }, { auth }) =>
    updateStoreConfiguration(auth.token, payload)
      .chain(res => of([updateReduxStores(res.data), setSelectedStore(res.data.id)]))
      .chainRej(err => of(console.log(err))),
}

const onUpdateBulkStoreConfigurationEpic = {
  type: onUpdateBulkStoreConfiguration.toString(),
  do: ({ payload }, { auth }) =>
    bulkUpdateStoreConfiguration(auth.token, payload)
      .chain(res =>
        of([
          updateReduxStores(res.data),
          setGlobalStoresSnackbar({
            show: true,
            success: true,
            message: storeConfigurationSnackbarMap.success[auth.language],
          }),
          setGlobalStoresIsLoading(false),
          setSelectedStore(auth.selectedStore.id),
        ])
      )
      .chainRej(
        err =>
          console.error(err.message) ||
          of([
            setGlobalStoresSnackbar({
              show: true,
              success: false,
              message: storeConfigurationSnackbarMap.error[auth.language],
            }),
            setGlobalStoresIsLoading(false),
          ])
      ),
}

const getAllSameDayStoresEpic = {
  type: getAllSameDayStores.toString(),
  do: (_, { auth }) =>
    fetchAllStores(auth.token, auth.userInfo.stores)
      .chain(res =>
        of([updateReduxStores(res.data), setGlobalStoresSnackbar(false), setSelectedStore(auth.selectedStore.id)])
      )
      .chainRej(err => of(setGlobalStoresIsLoading(false))),
}

export default [onUpdateStoreConfigurationEpic, onUpdateBulkStoreConfigurationEpic, getAllSameDayStoresEpic]
