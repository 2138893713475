import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingScreen } from '../../loading/loading'
import { DataGridTable } from '../reusables/dataGridTable'
import { GridColDef } from '@material-ui/data-grid'
import { makeStyles, Card, Typography } from '@material-ui/core'
import { moveDcUp } from '../../utils/sort'
import { globalDeliveriesMap } from './globalDeliveriesContent'

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'fixed',
    top: '100px',
    left: '60%',
    'z-index': '1',
  },
  heading: {
    paddingBottom: 40,
  },
  card: {
    width: '100%',
  },
  attentionHeader: {
    background: '#F0E68C',
  },
}))

export const GlobalDeliveries = () => {
  const { storesWithTodaysDeliveries, isLoading } = useSelector(state => state.globalDeliveries)
  const sortedStores = storesWithTodaysDeliveries.slice().sort(moveDcUp)
  const { language } = useSelector(state => state.auth)
  const classes = useStyles()

  // Sort done on the UI
  const [sortModel, setSortModel] = useState([
    {
      field: 'inPreparationBeforeThreshold',
      sort: 'desc',
    },
  ])

  const columns: GridColDef[] = [
    { field: 'mallName', headerName: globalDeliveriesMap.mallName[language], width: 200 },
    { field: 'chainName', headerName: globalDeliveriesMap.banner[language], width: 150 },
    { field: 'id', headerName: globalDeliveriesMap.storeNum[language], width: 150 },
    { field: 'country', headerName: globalDeliveriesMap.country[language], width: 150 },
    { field: 'state', headerName: globalDeliveriesMap.province[language], width: 150 },
    { field: 'city', headerName: globalDeliveriesMap.city[language], width: 150 },
    { field: 'phoneNumber', headerName: globalDeliveriesMap.phoneNumber[language], width: 150, filterable: false },
    { field: 'lastOrderDate', headerName: globalDeliveriesMap.lastOrderDate[language], width: 150 },
    { field: 'todaysDeliveriesCount', headerName: globalDeliveriesMap.todaysDeliveries[language], width: 150, filterable: false },
    { field: 'inPreparationCount', headerName: globalDeliveriesMap.inPreparation[language], width: 150, filterable: false },
    {
      field: 'inPreparationBeforeThreshold',
      headerName: globalDeliveriesMap.before3Pm[language],
      width: 200,
      filterable: false,
      headerClassName: `${classes.attentionHeader}`,
    },
    { field: 'awaitingPickupCount', headerName: globalDeliveriesMap.awaitingPickup[language], width: 150, filterable: false },
    { field: 'outForDeliveryCount', headerName: globalDeliveriesMap.outForDelivery[language], width: 150, filterable: false },
    { field: 'cancelledCount', headerName: globalDeliveriesMap.cancelled[language], width: 150, filterable: false },
    { field: 'completedCount', headerName: globalDeliveriesMap.completed[language], width: 150, filterable: false },
  ]

  return (
    <>
      <div>
        {isLoading && (
          <div className={classes.loading}>
            <LoadingScreen />
          </div>
        )}
        <Typography className={classes.heading} variant="h4">
          {globalDeliveriesMap.deliveriesGlobalView[language]}
        </Typography>
      </div>
      <Card className={classes.card}>
        <DataGridTable
          columns={columns}
          rows={sortedStores || []}
          toolbar={true}
          autoHeight={true}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          sortModel={sortModel}
        />
      </Card>
    </>
  )
}
