import { of } from 'fluture'
import {
  getDistrictSupervisors,
  setSupervisorList,
  setIsLoading,
  updateSupervisorList,
  setSnackbar,
  setIsButtonDisabled,
} from './dssConfiguration.redux'
import { getAllDistrictSupervisors, setStoreSupervisors } from './dssConfiguration.io'
import { updateStores } from '../auth/auth.redux'
import { dssSnackbarMap } from './dssConfigurationContent'

const getDistrictSupervisorsEpic = {
  type: getDistrictSupervisors.toString(),
  do: ({ payload: { manualTrigger } }, { auth }) =>
    getAllDistrictSupervisors(auth.refreshToken)
      .chain(res =>
        manualTrigger
          ? of([
              setSupervisorList(res.data),
              setIsLoading(false),
              setSnackbar({ show: true, success: true, message: dssSnackbarMap.getAllDssSuccess[auth.language] }),
            ])
          : of([setSupervisorList(res.data), setIsLoading(false)])
      )
      .chainRej(e =>
        of([
          setIsLoading(false),
          setSnackbar({
            show: true,
            success: false,
            message: `${dssSnackbarMap.getAllDssError[auth.language]} - ${e.response.data}`,
          }),
        ])
      ),
}

const updateDistrictSupervisorsEpic = {
  type: updateSupervisorList.toString(),
  do: ({ payload }, { auth }) =>
    setStoreSupervisors(payload)
      .chain(res =>
        of([
          updateStores(res.data),
          setIsLoading(false),
          setSnackbar({ show: true, success: true, message: dssSnackbarMap.setStoreDssSuccess[auth.language] }),
          setIsButtonDisabled(true),
        ])
      )
      .chainRej(e =>
        of([
          setIsLoading(false),
          setSnackbar({
            show: true,
            success: false,
            message: `${dssSnackbarMap.setStoreDssError[auth.language]}: ${e.response.data}`,
          }),
        ])
      ),
}

export default [getDistrictSupervisorsEpic, updateDistrictSupervisorsEpic]
