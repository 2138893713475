import { createAction, handleActions } from 'redux-actions'
import { Role, UserLoggedIn } from '../../utils/types'
import { USER_ROLE, LANGUAGE } from '../../const'

export const loginSuccess = createAction('AUTH_LOGIN')
export const logout = createAction('AUTH_LOGOUT')
export const tryLogin = createAction('AUTH_TRY_LOGIN')
export const setDomainSnackbar = createAction('AUTH_SET_DOMAIN_SNACKBAR')
export const setLanguage = createAction('AUTH_SET_LANGUAGE')
export const setSelectedStore = createAction('AUTH_SET_SELECTED_STORE')
export const setLoginError = createAction('AUTH_LOGIN_ERROR')
export const setLoading = createAction('AUTH_SET_LOADING')

export const updateReduxStores = createAction('UPDATE_STORE_LIST')
export const updateStores = createAction('UPDATE_STORES')
export const onUpdateStoreConfiguration = createAction('UPDATE_STORE_CONFIGURATION')
export const onUpdateBulkStoreConfiguration = createAction('UPDATE_BULK_STORE_CONFIGURATION')

const INITIAL_STATE = {
  isLoggedIn: UserLoggedIn.No,
  userInfo: {
    displayName: null,
    role: Role.None,
    stores: [],
  },
  token: null,
  refreshToken: null,
  loginError: null,
  selectedStore: null,
  language: null,
}

export const getDefaultStore = userInfo => {
  if (userInfo.role === USER_ROLE.ADMIN) {
    const defaultStore = userInfo.stores.find(store => store.id === 'DC')

    if (defaultStore) {
      return defaultStore
    }
  }

  return userInfo.stores[0]
}

export const getStoreById = (id, stores) => stores.find(store => store.id === id)

export const setIsLoggedIn = token => (token ? UserLoggedIn.Yes : UserLoggedIn.No)

export const setUserRole = role => {
  switch (role) {
    case USER_ROLE.ADMIN:
      return Role.Admin
    case USER_ROLE.DISTRICT_SUPERVISOR:
      return Role.DistrictSupervisor
    case USER_ROLE.MANAGER:
      return Role.Manager
    case USER_ROLE.EMPLOYEE:
      return Role.Employee
    default:
      return Role.None
  }
}
const updateStoreList = (updatedStore, stores) => {
  var updateStoreIndex = stores.findIndex(x => x.id === updatedStore.id)
  stores.splice(updateStoreIndex, 1, updatedStore)

  return stores
}

const getLanguageCookie = () => localStorage.getItem('oneDayDashboardLanguage') || LANGUAGE.EN

export const authReducer = handleActions(
  {
    [loginSuccess]: (state, { payload: { userInfo, token, refreshToken } }) => ({
      ...state,
      userInfo: {
        ...userInfo,
        role: setUserRole(userInfo.role),
      },
      isLoggedIn: setIsLoggedIn(token),
      token,
      refreshToken,
      selectedStore: getDefaultStore(userInfo),
      language: getLanguageCookie(),
    }),
    [logout]: () => ({
      ...INITIAL_STATE,
    }),
    [setLanguage]: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
    [setSelectedStore]: (state, { payload }) => ({
      ...state,
      selectedStore: getStoreById(payload, state.userInfo.stores),
    }),
    [updateReduxStores]: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        stores: !Array.isArray(payload) ? updateStoreList(payload, state.userInfo.stores) : payload,
      },
    }),
    [setLoginError]: (state, { payload }) => ({
      ...state,
      loginError: payload,
    }),
    [setLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    [updateStores]: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        stores: payload,
      },
    }),
  },
  INITIAL_STATE
)
