import { createAction, handleActions } from 'redux-actions'

export const getAllSameDayStores = createAction('GLOBAL_STORES_CONFIG_GET_ALL_SAMEDAY_STORES')
export const setGlobalStoresSnackbar = createAction('GLOBAL_STORES_CONFIG_SET_SNACKBAR')
export const setGlobalStoresIsLoading = createAction('GLOBAL_STORES_CONFIG_SET_IS_LOADING')

const INITIAL_STATE = {
  globalStoresIsLoading: false,
  globalStoresSnackbar: {
    show: false,
    success: null,
    message: null,
  },
}

export const isValidValue = value => (!!value && value >= 0 && value < 23 ? true : false)

export const isFormInvalid = stores => Object.values(stores).some(store => store.radiusError)

export const formatUpdateStoresArray = stores =>
  stores.map(store => ({
    id: store.id,
    storeRadius: store.storeRadius,
    sameDayActivation: store.sameDayActivation,
    hasEdit: store.hasEdit,
  }))

export const storeConfigReducer = handleActions(
  {
    [setGlobalStoresIsLoading]: (state, { payload }) => ({
      ...state,
      globalStoresIsLoading: payload,
    }),
    [setGlobalStoresSnackbar]: (state, { payload }) => ({
      ...state,
      globalStoresSnackbar: {
        show: payload.show,
        success: payload.success,
        message: payload.message,
      },
    }),
  },
  INITIAL_STATE
)
