/**
 * @param {Object} a object
 * @param {Object} b object
 * @param {string} orderBy string
 * @returns {number} number (-1, 0, 1)
 */
export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  if (a[orderBy] === undefined) return -1
  if (b[orderBy] === undefined) return 1
  return 0
}

/**
 * @param {string} order order
 * @param {string} orderBy orderBy
 * @returns {function} comparator function
 */
export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

/**
 *
 * @param {Object[]} array array of object data
 * @param {function} comparator comparator function
 * @returns {Object[]} array of sorted object data
 */
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const isDC = store => store.id.toLowerCase() === 'dc'

const setStoreName = store => {
  return isDC(store)
    ? 'Distribution Center - DC'
    : `${store.mallName || store.storeName} - ${store.chainName} - ${store.id}`
}

const sortStoreByName = (s1, s2) => (s1.name < s2.name ? -1 : 1)

export const moveDcUp = store => (isDC(store) ? -1 : 0)

export const getSortedStores = stores =>
  stores
    // new property "name" added, so that is can be sorted
    .map(s => ({ ...s, name: setStoreName(s) }))
    .sort(sortStoreByName)
    .sort(moveDcUp)
