import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'
import { getStoreIds } from './searchResults.redux'

export const fetchSearchResults = (token, orderId, stores) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `/getOrderWithTripsByOrderId`,
      data: {
        orderId,
        storeIds: getStoreIds(stores),
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
