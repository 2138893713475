import { createAction, handleActions } from 'redux-actions'

export const setPostalCodes = createAction('POSTAL_CODE_CONFIG_SET_POSTAL_CODES')
export const postPostalCode = createAction('POSTAL_CODE_CONFIG_POST_POSTAL_CODE')
export const deletePostalCode = createAction('POSTAL_CODE_CONFIG_DELETE_POSTAL_CODE')
export const setPostalCodeSnackBar = createAction('POSTAL_CODE_CONFIG_SET_POSTALCODE_SNACKBAR')

const INITIAL_STATE = {
  postalCodes: [],
  postalCodeSnackBar: {
    success: false,
    message: '',
    show: false,
  },
}

export const postalCodeConfigReducer = handleActions(
  {
    [setPostalCodes]: (state, { payload }) => ({
      ...state,
      postalCodes: payload,
    }),
    [setPostalCodeSnackBar]: (state, { payload }) => ({
      ...state,
      postalCodeSnackBar: {
        success: payload.success,
        message: payload.message,
        show: payload.show,
      },
    }),
  },
  INITIAL_STATE
)
