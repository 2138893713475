import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles, Paper, Grid, Button } from '@material-ui/core'
import { GoogleAddressSuggestions } from '../reusables/googleAddressSuggestions'
import { FormBuilder } from '../reusables/formBuilder'
import { DialogBuilder } from '../reusables/dialogBuilder'
import { requiredValidation, patternValidation } from '../reusables/formaValidationHelpers'
import { labelMap, initialFormValues, discardDialog, newOrderComplete, reminderPopup } from './newDeliveryContent'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import { Popup } from '../reusables/popup'
import { fullAddress, isNotOnlyWhitespace } from '../../utils/string'
import {
  getOrderData,
  createUberDelivery,
  setCreateDeliveryStatus,
  setRedirectToTodaysDeliveries,
  setDisableNewDeliveryButtons,
  setIsOrderIdDisabled,
  setReminderPopup,
} from '../todaysDeliveries/todaysDeliveries.redux'

const useStyles = makeStyles(theme => ({
  pageContent: {
    marginLeft: '20%',
    marginRight: '20%',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1%',
      marginRight: '1%',
    },
  },
  subHeading: {
    marginBottom: '1px',
  },
  button: {
    margin: '15px 20px 0px 20px',
  },
}))

export const NewDelivery = () => {
  const [values, setValues] = useState(initialFormValues)
  const { selectedStore, language } = useSelector(state => state.auth)
  const [isFormReady, setIsFormReady] = useState(false)
  const {
    orderData,
    createDeliveryStatus,
    redirectToTodaysDeliveries,
    disableNewDeliveryButtons,
    isOrderIdDisabled,
    isReminderPopupOpen,
  } = useSelector(state => state.today)
  const [isFormDisabled, setIsFormDisabled] = useState(true)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const setSnackAlertMessage = () => {
    if (!selectedStore.uberStoreID) {
      return newOrderComplete.missingStoreId[language]
    }
    return createDeliveryStatus.success
      ? newOrderComplete.successfulOrder[language]
      : newOrderComplete.unsuccessfulOrder[language]
  }

  //Triggers when another store is selected
  useEffect(() => {
    if (selectedStore) {
      const pickupAddressField = values['pickupAddress']
      const pickupPhoneField = values['pickupPhone']
      const { address1, city, state, country, phoneNumber } = selectedStore
      setValues({
        ...values,
        pickupAddress: { ...pickupAddressField, value: `${address1}, ${city}, ${state}, ${country}` },
        pickupPhone: { ...pickupPhoneField, value: phoneNumber, error: false, helperText: '' },
        orderId: { ...values['orderId'], error: false, helperText: '' },
      })

      if (!selectedStore.uberStoreID) {
        setCreateDeliveryStatus({ success: false, showSnackBar: true })
      }
      setIsFormDisabled(true)
      setIsFormReady(true)
    }
  }, [selectedStore])

  //Triggers when OrderId validation is happened
  useEffect(() => {
    const orderIdField = values['orderId']
    if (orderIdField.value) {
      const isOrderIdValid = Array.isArray(orderData) && orderData.length
      const validationField = {
        ...orderIdField,
        error: !isOrderIdValid,
        helperText: !isOrderIdValid ? orderIdField.failedText[language] : '',
      }

      if (!isOrderIdValid) {
        setValues({ ...values, orderId: validationField })
      } else {
        const pickupPhoneValue = selectedStore && selectedStore.phoneNumber ? selectedStore.phoneNumber : ''
        const dropoffAddressValue = orderData[0].customerAddress ? fullAddress(orderData[0].customerAddress) : ''
        const dropoffPlaceId = orderData[0].customerGooglePlaceId ? orderData[0].customerGooglePlaceId : ''
        const dropoffPhoneValue =
          (orderData[0].customerPhone && orderData[0].customerPhone.split('+1')[1]) ||
          orderData[0].customerAddress.Phone
        const dropoffInstructionValue =
          orderData[0].customerAddress &&
          orderData[0].customerAddress.Address1 &&
          selectedStore.dropoffInstruction &&
          isNotOnlyWhitespace(selectedStore.dropoffInstruction)
            ? `${orderData[0].customerAddress.Address1}\n${selectedStore.dropoffInstruction}`
            : orderData[0].customerAddress &&
              orderData[0].customerAddress.Address1 &&
              (!selectedStore.dropoffInstruction || !isNotOnlyWhitespace(selectedStore.dropoffInstruction))
            ? `${orderData[0].customerAddress.Address1}\n${initialFormValues['dropoffInstruction'].value[language]}`
            : initialFormValues['dropoffInstruction'].value
        const pickupInstructionValue =
          orderData[0].customerAddress &&
          orderData[0].customerAddress.Address1 &&
          selectedStore.pickupInstruction &&
          isNotOnlyWhitespace(selectedStore.pickupInstruction)
            ? `${selectedStore.pickupInstruction}`
            : initialFormValues['pickupInstruction'].value

        setValues({
          ...values,
          userData: orderData[0],
          orderId: validationField,
          pickupPhone: { ...values['pickupPhone'], value: pickupPhoneValue, error: false, helperText: '' },
          dropoffAddress: {
            ...values['dropoffAddress'],
            error: false,
            helperText: '',
            value: { placeId: dropoffPlaceId, value: dropoffAddressValue },
          },
          dropoffInstruction: { ...values['dropoffInstruction'], value: dropoffInstructionValue },
          pickupInstruction: { ...values['pickupInstruction'], value: pickupInstructionValue },
          dropoffPhone: { ...values['dropoffPhone'], value: dropoffPhoneValue, error: false, helperText: '' },
        })
        setIsFormDisabled(false)
        setIsOrderIdDisabled(false)
      }
    }
  }, [orderData])

  useEffect(() => {
    if (isFormReady) {
      const form = Object.entries(values).reduce((acc, [key, field]) => {
        if (field.error) {
          const hasValue = key === 'dropoffAddress' ? !!getAddressValue() : !!field.value.trim()
          field.helperText = hasValue ? values[key].failedText[language] : labelMap.fields.require[language]
        }
        acc[key] = field
        return acc
      }, {})
      setValues(form)
    }
  }, [language])

  //Triggers when a new trip is created successfully
  useEffect(() => {
    if (redirectToTodaysDeliveries) {
      dispatch(setCreateDeliveryStatus({ ...createDeliveryStatus, showSnackBar: false }))
      dispatch(setDisableNewDeliveryButtons(false))
      dispatch(setRedirectToTodaysDeliveries(false))
    }
  }, [redirectToTodaysDeliveries])

  useEffect(() => {
    if (!createDeliveryStatus.success) {
      setIsOrderIdDisabled(false)
    }
  }, [createDeliveryStatus])

  const handleInputChange = e => {
    const { name, value } = e.target
    const field = values[name]
    setValues({
      ...values,
      [name]: { ...field, value: name === 'orderId' ? value.trim() : value },
    })

    //Form will be disabled if the user changes orderId after validating
    if (name === 'orderId' && !isFormDisabled) {
      setIsFormDisabled(true)
    }
  }

  const handleDropOffAddressChange = e => {
    const { name, value } = e.target
    const field = values[name]

    if (name === 'dropoffAddress' && e.target.select) {
      setValues({
        ...values,
        [name]: { ...field, value },
      })
    } else {
      setValues({
        ...values,
        [name]: { ...field, value: { ...field.value, value, placeId: '' } },
      })
    }
  }

  const formValidation = async () => {
    let formValid = true
    const validationFields = {}

    for (const fieldName of Object.keys(values)) {
      const field = values[fieldName]
      validationFields[fieldName] = field
      //required validation
      if (field.required) {
        const isEmpty = requiredValidation(field.value)
        validationFields[fieldName] = {
          ...field,
          error: isEmpty,
          helperText: isEmpty ? labelMap.fields.require[language] : '',
        }
        formValid = formValid && !isEmpty
      }
      //pattern validation
      if (field.pattern && !validationFields[fieldName].error) {
        const isPatternValid = patternValidation(field.pattern.regex, field.value)
        validationFields[fieldName] = {
          ...field,
          error: !isPatternValid,
          helperText: !isPatternValid ? field.failedText[language] : '',
        }
        formValid = formValid && isPatternValid
      }
      //placeId validation
      if (fieldName === 'dropoffAddress' && !validationFields['dropoffAddress'].error) {
        const hasPlaceId = values[fieldName].value.placeId
        validationFields[fieldName] = {
          ...field,
          error: !hasPlaceId,
          helperText: !hasPlaceId ? field.failedText[language] : '',
        }
        formValid = formValid && hasPlaceId
      }
    }

    setValues({ ...values, ...validationFields })
    return formValid
  }

  const orderIdValidation = async () => {
    const orderIdField = values['orderId']
    //required validation
    if (orderIdField.required) {
      const isEmpty = requiredValidation(orderIdField.value)
      const validationField = {
        ...orderIdField,
        error: isEmpty,
        helperText: isEmpty ? labelMap.fields.require[language] : '',
      }
      setValues({ ...values, orderId: validationField })
    }
    //orderId check against MongoDB
    if (orderIdField.value) {
      dispatch(getOrderData({ orderId: orderIdField.value, selectedStore }))
    }
  }

  const requestNewDelivery = async () => {
    const isFormValid = await formValidation()
    if (!isFormValid) {
      return false
    }
    setIsFormDisabled(true)
    const pickupPhone = labelMap.fields.countryCode[language] + values.pickupPhone.value.replace(/\D/g, '')
    const dropoffPhone = labelMap.fields.countryCode[language] + values.dropoffPhone.value.replace(/\D/g, '')
    const payload = {
      orderId: values.orderId.value,
      pickupInstruction: `Order/Commande: ${values.orderId.value} - ${values.pickupInstruction.value}`,
      pickupPhone: pickupPhone,
      dropoffPlaceId: values.dropoffAddress.value.placeId,
      dropoffInstructions: values.dropoffInstruction.value,
      dropoffPhone: dropoffPhone,
      dropoffOption: values.dropoffOption.value,
      storeId: selectedStore.id,
      uberStoreID: selectedStore.uberStoreID,
      customerFirstName: values.userData.customerFirstName,
      customerLastName: values.userData.customerLastName,
      customerEmail: values.userData.customerEmail,
      orderItems: values.userData.orderItems,
      estimatedDistance: values.userData.estimatedDistance || null,
    }

    dispatch(createUberDelivery({ payload }))
    dispatch(setDisableNewDeliveryButtons(true))
  }

  const getAddressValue = () => {
    const address = values.dropoffAddress && values.dropoffAddress.value
    if (address && typeof address === 'object') {
      return address.value
    }
    return address
  }

  const closePopUpHandler = () => {
    dispatch(setReminderPopup(false))
    history.push('/todaysDeliveries')
  }

  return (
    <>
      <Paper className={classes.pageContent}>
        <h2>{labelMap.headers.requestDelivery[language]}</h2>
        <form className={classes.root}>
          <h3 className={classes.subHeading}>{labelMap.headers.packageInformation[language]}</h3>
          <Grid container flex-direction="row" justify="space-evenly" alignItems="center">
            <FormBuilder
              fields={{
                orderId: {
                  ...values['orderId'],
                  label: labelMap.fields.orderId[language],
                  name: 'orderId',
                  type: 'input',
                  inputAdornment: '',
                  onChange: handleInputChange,
                  disabled: isOrderIdDisabled,
                },
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={orderIdValidation}
              disabled={disableNewDeliveryButtons}
            >
              {labelMap.fields.validate[language]}
            </Button>
          </Grid>
          <h3 className={classes.subHeading}>{labelMap.headers.pickupDetails[language]}</h3>
          <FormBuilder
            fields={{
              pickupAddress: {
                ...values['pickupAddress'],
                label: labelMap.fields.pickupAddress[language],
                name: 'pickupAddress',
                type: 'input',
                multiline: true,
                inputAdornment: '',
                onChange: handleInputChange,
              },
              pickupInstruction: {
                ...values['pickupInstruction'],
                label: labelMap.fields.pickupInstruction[language],
                name: 'pickupInstruction',
                type: 'input',
                multiline: true,
                inputAdornment: '',
                disabled: isFormDisabled,
                onChange: handleInputChange,
              },
              pickupPhone: {
                ...values['pickupPhone'],
                label: labelMap.fields.pickupPhone[language],
                name: 'pickupPhone',
                type: 'input',
                required: true,
                inputAdornment: labelMap.fields.countryCode[language],
                disabled: isFormDisabled,
                onChange: handleInputChange,
              },
            }}
          />
          <h3 className={classes.subHeading}>{labelMap.headers.dropOffDetails[language]}</h3>
          <GoogleAddressSuggestions
            address={getAddressValue()}
            label={labelMap.fields.dropoffAddress[language]}
            name="dropoffAddress"
            inputAdornment=""
            changed={handleDropOffAddressChange}
            required={values.dropoffAddress.required}
            helperText={values.dropoffAddress.helperText}
            error={values.dropoffAddress.error}
            disabled={isFormDisabled}
          />
          <FormBuilder
            fields={{
              dropoffInstruction: {
                ...values['dropoffInstruction'],
                label: labelMap.fields.dropoffInstruction[language],
                name: 'dropoffInstruction',
                type: 'input',
                multiline: true,
                inputAdornment: '',
                disabled: isFormDisabled,
                onChange: handleInputChange,
              },
              dropoffPhone: {
                ...values['dropoffPhone'],
                label: labelMap.fields.dropoffPhone[language],
                name: 'dropoffPhone',
                type: 'input',
                inputAdornment: labelMap.fields.countryCode[language],
                disabled: isFormDisabled,
                onChange: handleInputChange,
              },
              dropoffOption: {
                ...values['dropoffOption'],
                label: labelMap.fields.dropoffOption[language],
                name: 'dropoffOption',
                type: 'select',
                options: labelMap.fields.deliveryOptions[language],
                disabled: isFormDisabled,
                onChange: handleInputChange,
              },
            }}
          />
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              disabled={disableNewDeliveryButtons || isFormDisabled}
            >
              {labelMap.fields.discard[language]}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setIsOrderIdDisabled(true))
                requestNewDelivery()
              }}
              disabled={disableNewDeliveryButtons || isFormDisabled}
            >
              {labelMap.fields.request[language]}
            </Button>
          </Grid>
        </form>
      </Paper>
      <DialogBuilder
        content={{
          text: discardDialog.text[language],
          disagree: discardDialog.disagree[language],
          agree: discardDialog.agree[language],
        }}
        open={isDialogOpen}
        onDisagree={() => setIsDialogOpen(false)}
        onAgree={() => {
          history.push('/todaysDeliveries')
        }}
      />
      <SnackbarBuilder
        contentText={setSnackAlertMessage()}
        isOpen={createDeliveryStatus.showSnackBar}
        handleClose={() => dispatch(setCreateDeliveryStatus({ ...createDeliveryStatus, showSnackBar: false }))}
        severityLevel={createDeliveryStatus.success ? 'success' : 'error'}
        location={{ vertical: 'top', horizontal: 'center' }}
      />
      <Popup
        isOpen={isReminderPopupOpen}
        dialogTitle={reminderPopup.title[language]}
        dialogContentText={`${reminderPopup.text[language]} ${values.orderId.value}`}
        submitButtonText={`${reminderPopup.agree[language]}`}
        onConfirmClick={closePopUpHandler}
        showCancelButton={false}
      /> 
    </>
  )
}
