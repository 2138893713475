export const initialFormValues = {
  pickupAddress: {
    value: '',
    error: false,
    helperText: '',
    required: true,
    disabled: true,
  },
  pickupInstruction: {
    value: 'Eg: Pick up at the Front Desk / Ramasser à la réception',
    error: false,
    helperText: '',
    required: false,
  },
  pickupPhone: {
    value: '',
    error: false,
    helperText: '',
    required: true,
    pattern: {
      regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    },
    failedText: {
      en: 'Enter valid contact number',
      fr: 'Entrez un numéro de contact valide',
    },
  },
  dropoffAddress: {
    value: '',
    error: false,
    helperText: '',
    required: true,
    failedText: {
      en: 'Please select address from suggestions',
      fr: 'Veuillez sélectionner une adresse parmi les suggestions',
    },
  },
  dropoffPhone: {
    value: '',
    error: false,
    helperText: '',
    required: true,
    pattern: {
      regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    },
    failedText: {
      en: 'Enter valid contact number',
      fr: 'Entrez un numéro de contact valide',
    },
  },
  dropoffOption: {
    value: 'Leave at Door / Laisser à la porte',
    error: false,
    helperText: '',
    required: false,
  },
  orderId: {
    value: '',
    error: false,
    helperText: '',
    required: true,
    failedText: {
      en: 'This OrderId is not valid',
      fr: `Ce numéro de commande n'est pas valide`,
    },
  },
  dropoffInstruction: {
    value: `Si aucune réponse à la porte, svp appeler le client (numéro de téléphone écrit sur le colis) avant de laisser le colis devant la porte. Si c'est une tour à condo/bureau et qu'il n'y a pas de sécurité ou concierge, svp appeler le client avant de retourner le colis à l'origine // If there's no answer at the door, please call customer‘s phone number (written on the package) before leaving the order at the door. As for apartment/office buildings, if there's no concierge or security, please call the customer before returning it to the sender.`,
    error: false,
    helperText: '',
    required: false,
  },
}

export const labelMap = {
  headers: {
    requestDelivery: { en: 'Request Delivery', fr: 'Demander une livraison' },
    pickupDetails: { en: 'Pickup Details', fr: 'Détails ramassage' },
    dropOffDetails: { en: 'Dropoff Details', fr: 'Livraison détails' },
    packageInformation: { en: 'Package Information', fr: 'Information sur le paquet' },
  },
  fields: {
    pickupAddress: { en: 'Pickup Address', fr: 'Adresse ramassage' },
    pickupInstruction: { en: 'Note for pickup (Optional)', fr: 'Notes ramassage (optionel)' },
    pickupPhone: { en: 'Contact Number', fr: 'Numéro contact' },
    dropoffAddress: { en: 'Dropoff Address', fr: 'Livraison adresse' },
    dropoffInstruction: { en: 'Note for dropoff (optional)', fr: 'Notes livraison (optionel)' },
    dropoffPhone: { en: 'Contact Number', fr: 'Numéro contact' },
    dropoffOption: { en: 'Delivery Option', fr: 'Option de livraison' },
    orderId: { en: 'Order ID', fr: 'No commande' },
    deliveryOptions: { en: ['Leave at Door', 'Door', 'Curbside'], fr: ['Laisser à la porte', 'Porte', 'Bord de rue'] },
    countryCode: {
      en: '+1',
      fr: '+1',
    },
    request: { en: 'Request Now', fr: 'Demander maintenant' },
    discard: { en: 'Discard', fr: 'Abandonner' },
    validate: { en: 'Validate', fr: 'Valider' },
    require: { en: 'Required', fr: 'Requis' },
  },
}

export const discardDialog = {
  text: {
    en: `Are you sure you want to discard this ride? You’ll lose all the progress you’ve made so far`,
    fr: `Voulez-vous vraiment supprimer ce trajet ? Vous perdrez tous les progrès que vous avez fait jusqu'à présent`,
  },
  disagree: {
    en: `No, undo`,
    fr: 'Non, annuler',
  },
  agree: { en: `Yes, Discard`, fr: 'Oui, abandonner' },
}

export const newOrderComplete = {
  successfulOrder: { en: `Order request Successful!`, fr: 'Demande de livraison réussie!' },
  unsuccessfulOrder: { en: `Something went wrong`, fr: `Une erreur s'est produite.` },
  missingStoreId: {
    en: `Selected store is missing data and cannot be used to create new trips`,
    fr:
      'Il manque des données pour le magasin sélectionné et il ne peut pas être utilisé pour créer de nouvelles livraisons',
  },
}

export const reminderPopup = {
  title: {
    en: 'Reminder',
    fr: 'Rappel',
  },
  text: {
    en: `Don’t forget to close the manifest for order`,
    fr: `N’oublie pas de fermer le manifeste pour la commande`,
  },
  agree: { en : 'OK', fr: 'OK' },
}