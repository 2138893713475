import { cancelTrip, setCancelledSnackBar, onGetTodaysDeliveries, setRedirectToAwaitingPickup } from '../todaysDeliveries/todaysDeliveries.redux'
import { cancelSameDayTrip } from './cancelTrip.io'
import { of } from 'fluture'

const cancelTripEpic = {
  type: cancelTrip.toString(),
  do: ({ payload: tripId }, { auth }) => 
    cancelSameDayTrip( auth.token, tripId)
      .chain((res) => of([onGetTodaysDeliveries(true), setCancelledSnackBar({success: true, message: res.data, show: true}), setRedirectToAwaitingPickup(true)]))
      .chainRej((err) => of([setCancelledSnackBar({success: false, message: err.response.data, show: true})]))
}

export default [cancelTripEpic]