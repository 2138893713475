export const labelMap = {
  headers: {
    CA: {
      pageTitle: {
        en: 'One Day Excluded Postal Codes',
        fr: 'Codes Postaux Exclus',
      },
      tableFirstColumn: {
        en: 'Postal Code',
        fr: 'Code postal',
      },
    },
    US: {
      pageTitle: {
        en: 'One Day Excluded Zip Codes',
        fr: 'Zip Codes exclus',
      },
      tableFirstColumn: {
        en: 'Zip Code',
        fr: 'Zip Code',
      },
    },
    tableSecondColumn: {
      en: 'Action',
      fr: 'Action',
    },
  },
  fields: {
    addPostalCode: {
      CA: {
        label: {
          en: 'Postal Code',
          fr: 'Code postal',
        },
        helperText: {
          en: 'Please enter at least the first 3 characters',
          fr: 'Vous devez entrer au moins 3 charactères',
        },
      },
      US: {
        label: {
          en: 'Zip Code',
          fr: 'Zip Code',
        },
        helperText: {
          en: 'Please enter zip code',
          fr: 'Vous devez entrer zip code',
        },
      },
    },
  },
  buttons: {
    add: {
      en: 'ADD',
      fr: 'AJOUTER',
    },
    delete: {
      en: 'DELETE',
      fr: 'SUPPRIMER',
    },
  },
}

export const postalCodeDialog = {
  text: {
    en: `Are you sure you want to delete this postal code from exclusion list?`,
    fr: `Voulez-vous vraiment supprimer ce code postal de la liste d'exclusion ?`,
  },
  disagree: {
    en: `No, I don’t`,
    fr: 'Non merci',
  },
  agree: {
    en: `Yes, I do`,
    fr: 'Oui, SVP',
  },
}

export const postalCodeSnackBarMessages = {
  added: {
    en: `Postal code is added to exclusion list`,
    fr: `Code postale ajouter à la liste d'exclusion`
  },
  removed: {
    en: `Postal code is removed from exclusion list`,
    fr: `Code postale retiré de la liste d'exclusion`
  }
}