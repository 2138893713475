import {
  getOrderData,
  setOrderData,
  createUberDelivery,
  setCreateDeliveryStatus,
  setRedirectToTodaysDeliveries,
  setDisableNewDeliveryButtons,
  setIsOrderIdDisabled,
  onGetTodaysDeliveries,
  setReminderPopup,
} from '../todaysDeliveries/todaysDeliveries.redux'
import { findByOrderID, createUberDeliveries } from './newDelivery.io'
import { of } from 'fluture'

const getOrderDataEpic = {
  type: getOrderData.toString(),
  do: ({ payload: { orderId, selectedStore } }, { auth }) =>
    findByOrderID(auth.token, orderId, selectedStore.id)
      .chain(res => of(setOrderData(res.data)))
      .chainRej(() => of(setOrderData({}))),
}

const createUberDeliveryEpic = {
  type: createUberDelivery.toString(),
  do: ({ payload: { payload } }, { auth }) =>
    createUberDeliveries(auth.token, payload)
      .chain(({ data }) =>
        of(
          data && data.order_tracking_url
            ? [
                setCreateDeliveryStatus({ success: true, showSnackBar: true }),
                setReminderPopup(true),
                setRedirectToTodaysDeliveries(true),
                onGetTodaysDeliveries(true),
                setIsOrderIdDisabled(false),
              ]
            : [
                setCreateDeliveryStatus({ success: false, showSnackBar: true }),
                setDisableNewDeliveryButtons(false),
                setIsOrderIdDisabled(false),
              ]
        )
      )
      .chainRej(() =>
        of([
          setCreateDeliveryStatus({ success: false, showSnackBar: true }),
          setDisableNewDeliveryButtons(false),
          setIsOrderIdDisabled(false),
        ])
      ),
}

export default [getOrderDataEpic, createUberDeliveryEpic]
