import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'
import { formatUpdateStoresArray } from './storeConfiguration.redux'
import { getStoreIds } from '../../utils/general'

export const updateStoreConfiguration = (token, payload) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/updateStoreConfiguration',
      data: payload,
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const bulkUpdateStoreConfiguration = (token, stores) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/bulkUpdateStoreConfiguration',
      data: {
        stores: formatUpdateStoresArray(stores),
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchAllStores = (token, stores) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/getAllSameDayStoresConfig',
      data: {
        storeIds: getStoreIds(stores),
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
