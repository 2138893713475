export const VALIDATION_REGEX = {
  POSTAL_CODE: {
    CA: /(^[A-Za-z][0-9][A-Za-z]$)|(^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$)/,
    US: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  },
}

export const validatePostalCode = (value, country) => VALIDATION_REGEX.POSTAL_CODE[country].test(value)

export const validateStoreConfigFields = (storeConfigSelectedStore, setStoreConfigSelectedStore) => {
  let valid = true

  if (storeConfigSelectedStore.sameDayActivation) {
    if (
      (!storeConfigSelectedStore.storeRadius && !storeConfigSelectedStore.uberStoreID) ||
      (!storeConfigSelectedStore.uberStoreID &&
        ((!!storeConfigSelectedStore.storeRadius && isNaN(Number(storeConfigSelectedStore.storeRadius))) ||
          (!!storeConfigSelectedStore.storeRadius && storeConfigSelectedStore.storeRadius === '')))
    ) {
      setStoreConfigSelectedStore({
        ...storeConfigSelectedStore,
        storeRadiusError: true,
        uberStoreIDError: true,
      })
      valid = false
    } else if (
      !storeConfigSelectedStore.storeRadius ||
      (!!storeConfigSelectedStore.storeRadius && isNaN(Number(storeConfigSelectedStore.storeRadius))) ||
      (!!storeConfigSelectedStore.storeRadius && storeConfigSelectedStore.storeRadius === '')
    ) {
      setStoreConfigSelectedStore({
        ...storeConfigSelectedStore,
        storeRadiusError: true,
        uberStoreIDError: false,
      })
      valid = false
    } else if (!storeConfigSelectedStore.uberStoreID) {
      setStoreConfigSelectedStore({
        ...storeConfigSelectedStore,
        storeRadiusError: false,
        uberStoreIDError: true,
      })
      valid = false
    } else {
      setStoreConfigSelectedStore({
        ...storeConfigSelectedStore,
        storeRadiusError: false,
        uberStoreIDError: false,
      })
    }
  } else if (
    (!!storeConfigSelectedStore.storeRadius && isNaN(Number(storeConfigSelectedStore.storeRadius))) ||
    storeConfigSelectedStore.storeRadius === ''
  ) {
    setStoreConfigSelectedStore({
      ...storeConfigSelectedStore,
      storeRadiusError: true,
      uberStoreIDError: false,
    })
    valid = false
  }

  return valid
}
