export const colors = {
  white: '#ffffff',
  black: '#000000',
  lightBlue: '#e7f0fe',
  dimGrey: '#696969',
  lightGrey: '#C3C6C9',
  fadedGrey: 'rgba(227, 226, 225, 0.3)',
  green: '#29cf3a',
  red: '#ff0000',
}
