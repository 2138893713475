import React from 'react'
import { DataGrid, GridToolbar } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core'
import { dataGridTableMap } from './dataGridTableContent'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-main': {
      width: '100%',
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '0px',
    },
    '& .MuiDataGrid-cell--withRenderer': {
      'line-height': 'normal !important',
      height: 100,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-row .MuiDataGrid-cell': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      'font-weight': 'bold',
    },
    '& .MuiCircularProgress-root': {
      'z-index': 1,
    },
    '& .MuiDataGrid-row': {
      '&:nth-child(2n)': {
        backgroundColor: 'rgba(235, 235, 235, .8)',
      },
    },
  },
}))

export const DataGridTable = ({
  rowHeight,
  classes,
  columns,
  rows,
  components,
  componentsProps,
  loading = false,
  toolbar = false,
  autoHeight = false,
  disableColumnSelector = false,
  disableSelectionOnClick = false,
  disableColumnMenu = false,
  sortModel=[]

}) => {
  const defaultClass = useStyles()
  const { language } = useSelector(state => state.auth)

  return (
    <DataGrid
      rowHeight={rowHeight}
      components={
        components
          ? components
          : toolbar
          ? {
              Toolbar: GridToolbar,
            }
          : {}
      }
      componentsProps={componentsProps}
      loading={loading}
      className={classes && classes.root ? classes.root : defaultClass.root}
      autoHeight={autoHeight}
      columns={columns}
      rows={rows}
      disableColumnSelector={disableColumnSelector}
      disableSelectionOnClick={disableSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
      sortModel={sortModel}
      localeText={dataGridTableMap[language]}
    />
  )
}
