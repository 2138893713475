export const orderCardsMap = {
  completedCount: {
    en: 'Completed Trips',
    fr: 'Livraisons complétées',
  },
  orderStatusMessage: {
    preparation: {
      en: 'In Preparation',
      fr: 'En Préparation',
    },
    awaitingPickup: {
      en: 'Pickup in [insertTime] min',
      fr: 'Ramassage dans [insertTime] min',
    },
    arrivedAtPickUp: { en: 'Courrier arrived', fr: 'Livreur est arrivé' },
    outForDelivery: {
      en: 'Dropoff in [insertTime] min',
      fr: 'Livraison dans [insertTime] min',
    },
    arrivedAtDropoff: { en: 'Courrier arrived at dropoff', fr: 'Courrier est arrivé' },
    return: { en: 'Return in progress', fr: 'Retour en cours' },
    cancelled: { en: 'Cancelled at Time [insertTime]', fr: 'Annulée à [insertTime]' },
    completed: { en: 'Completed at time [insertTime]', fr: 'Complétée à [insertTime]' },
  },
  headline: {
      en: 'Order [orderId] to',
      fr: 'Commande [orderId] au',
  },
  linkToTrip: {
    en: 'Link to trip',
    fr: 'Lien vers livraison',
  },
  itemList: {
    en: 'List of items',
    fr: 'Liste des items',
  },
  customer: {
    en: 'Customer',
    fr: 'Cliente',
  },
  pickUpInstructions: {
    en: 'Pick up Instruction',
    fr: 'Ramassage - notes',
  },
  dropOffInstructions: {
    en: 'Drop off Instruction',
    fr: 'Livraison - notes',
  },
  cancellationReason: {
    en: 'Remarks',
    fr: 'Remarques',
  },
  storeNumber: {
    en: 'Store Number',
    fr: 'Numéro de magasin',
  },
  lastUpdate: {
    en: 'Last updated',
    fr: 'Dernière mise à jour',
  },
  processingTime: {
    en: 'Processing Time',
    fr: 'Temps de traitement',
  },
  showMoreLabel: {
    en: 'show more',
    fr: 'afficher plus',
  },
}

export const cancellationReasonMap = {
  'Customer cancelled the order': {
    en: 'Customer cancelled the order',
    fr: 'Client a annulé la commande',
  },
  'Merchant cancelled the order': {
    en: 'Merchant cancelled the order',
    fr: 'Le commerçant a annulé la commande',
  },
  'Uber was unable to find a courier after requesting for ~30 minutes': {
    en: 'Uber was unable to find a courier after requesting for ~30 minutes',
    fr: `Uber n'a pas pu trouver de coursier après avoir demandé environ 30 minutes`,
  },
  'The courier cancelled after picking up item from the merchant': {
    en: 'The courier cancelled after picking up item from the merchant',
    fr: `Le coursier a annulé après avoir récupéré l'article chez le commerçant`,
  },
}
