import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const createUberDeliveries = (token, payload) =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/createUberDelivery',
      data: payload,
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const findByOrderID = (token, orderId, storeId) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: '/getOrderByOrderId',
      params: {
        orderId,
        storeId,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )