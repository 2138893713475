export const dataGridTableMap = {
    en: {
        toolbarFilters: 'Filters',
        toolbarDensity: 'Density',
        toolbarExport: 'Export',
        filterPanelOperators: 'Operators',
        filterPanelColumns: 'Columns',
        filterPanelInputLabel: 'Value',
        toolbarExportCSV: 'Download as CSV',
        filterPanelInputPlaceholder: 'Filter value',
        filterOperatorContains: 'contains',
        filterOperatorEquals: 'equals',
        filterOperatorStartsWith: 'starts with',
        filterOperatorEndsWith: 'ends with',
        toolbarDensityCompact: 'Compact',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Comfortable',
        MuiTablePagination: {
            labelRowsPerPage: 'Rows per page: ',
            labelDisplayedRows: ({ from, to, count }) =>
             `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
         }
    },
    fr: {
        toolbarFilters: 'Filtres',
        toolbarDensity: 'Densité',
        toolbarExport: 'Export',
        filterPanelOperators: 'Opérateurs',
        filterPanelColumns: 'Colonnes',
        filterPanelInputLabel: 'Valeur',
        toolbarExportCSV: 'Télécharger en CSV',
        filterPanelInputPlaceholder: 'Filtrer la valeur',
        filterOperatorContains: 'contient',
        filterOperatorEquals: 'égal à',
        filterOperatorStartsWith: 'commence par',
        filterOperatorEndsWith: 'se termine par',
        toolbarDensityCompact: 'Compacte',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Confortable',
        MuiTablePagination: {
            labelRowsPerPage: 'Lignes par page : ',
            labelDisplayedRows: ({ from, to, count }) =>
             `${from}-${to} de ${count !== -1 ? count : `plus que ${to}`}`
         }
    }
}