import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const cancelSameDayTrip = (token, tripId) =>
  tryP(() =>
    axios({
        method: 'POST',
        url: '/cancelSameDayTrip',
        params: { tripId },
        baseURL,
        headers: {
        authorization: `Bearer ${token}`,
        },
    })
  )
