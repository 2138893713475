import { taggedSum } from 'daggy'

export const Role = taggedSum('Role', {
  Admin: [],
  DistrictSupervisor: [],
  Manager: [],
  Employee: [],
  None: [],
})

export const UserLoggedIn = taggedSum('UserLoggedIn', {
  Yes: [],
  No: [],
})

export const Maybe = taggedSum('Maybe', {
  Just: ['value'],
  Nothing: [],
})
