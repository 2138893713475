import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const getAllDistrictSupervisors = token =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `/getDistrictSupervisors`,
      data: {
        token,
      },
      baseURL,
    })
  )

export const setStoreSupervisors = storeMap =>
  tryP(() =>
    axios({
      method: 'POST',
      url: `/setDistrictStoreSupervisors`,
      data: {
        stores: Object.values(storeMap),
      },
      baseURL,
    })
  )
