export const cancelTripDialogMap = {
    text: {
      en: 'Are you sure you want to cancel this trip?',
      fr: 'Es-tu certain de vouloir annuler cette livraison?',
    },
    disagree: {
      en: 'No, I don\'t',
      fr: 'Non merci',
    },
    agree: {
      en: 'Yes, I do',
      fr: 'Oui svp',
    },
    cancelText: {
      en: 'cancel',
      fr: 'annuler',
    },
  }
  
export const cancelTripSnackbarMap = {
    tripCancelledSuccessfully: {
        en: 'Trip cancelled successfully',
        fr: 'Livraison annulée avec succès'
    },
    somethingWentWrong: {
        en: 'Something went wrong',
        fr: 'Une erreur s\'est produite'
    },    
    missingParams : {
        en: 'Missing Params',
        fr: 'Paramètres manquants'
    },
    invalidTripId: {
        en: 'Invalid trip ID',
        fr: 'Ce numéro de livraison n\'est pas valide'
    },
    tripAlreadyCompleted: {
        en: 'Trip is already completed',
        fr: 'Livraison déjà complétée'
    },
    tripAlreadyCancelled: {
        en: 'Trip is already cancelled',
        fr: 'Livraison déjà annulée'
    },
    tripAlreadyOutForDelivery: {
        en: 'Trip is already out for delviery',
        fr: 'Livraison déjà en cours'
    }    
}
