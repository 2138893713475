export const globalStoreConfigurationMap = {
    configurationGlobalView: {
      en: 'Configuration - Global View',
      fr: 'Configuration - Vue Globale',
    },
    filters: {
        en: 'Filters',
        fr: 'Filtres'
    },
    density: {
        en: 'Density',
        fr: 'Densité'
    },
    export: {
        en: 'Export',
        fr: 'Export'
    },
    mallName: {
       en: 'Mall Name',
       fr: 'Centre commercial'
    },
    banner: {
        en: 'Banner',
        fr: 'Bannière'
    },
    storeNum: {
        en: 'Store #',
        fr: 'Magasin #'
    },
    country: {
        en: 'Country',
        fr: 'Pays'
    },
    province: {
        en: 'Province',
        fr: 'Province'
    },
    city: {
        en: 'City',
        fr: 'Ville'
    },
    acceptSameDayOrders: {
        en: 'Accept Same Day orders:',
        fr: 'Accepter commandes Same Day'
    },
    uberStoreID: {
        en: 'Uber Store ID:',
        fr: 'Uber Store ID:'
    },
    radius: {
        en: 'Radius (km):',
        fr: 'Rayon (km):'
    },
    postalCodeExclusion: {
        en: 'Postal Code Exclusions',
        fr: 'Exclusion de codes postaux'
    },
    submit: {
        en: 'Submit',
        fr: 'Soumettre'
    },
    rowsPerPage: {
        en: 'Rows per page',
        fr: 'Lignes par page'
    },
    radiusValue: {
        en: 'Enter value between 0 and 22',
        fr: 'Entrez une valeur entre 0 et 22'
    },
    areYouSure: {
        en: 'Are you sure',
        fr: 'Es-tu certain?'
    },
    stay: {
        en: 'Stay',
        fr: 'Rester'
    },
    leave: {
        en: 'Leave',
        fr: 'Quitter'
    },
    unsavedChanges: {
        en: 'There are unsaved changes on the page. Are you sure you want to leave?',
        fr: 'Il y a des modifications non enregistrées sur la page. Êtes-vous sûr de vouloir quitter?'
    },
    yes:{
        en: 'YES',
        fr: 'OUI'
    },
    no:{
        en: 'NO',
        fr: 'NON'
    }
  }
  
  