export const noResultsMap = {
  noResultsTitle: {
    en: 'No Results Found',
    fr: 'Aucun résultat trouvé',
  },
  noResultsSubtitle: {
    en: 'There are no deliveries today with this status.',
    fr: `Il n'y a pas de livraisons aujourd'hui avec ce statut`,
  },
  comingSoon: {
    en: 'Coming soon',
    fr: 'Bientôt disponible',
  },
}
