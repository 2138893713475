import {
  getTodaysDeliveries,
  setTodaysDeliveries,
  setLoading,
  onGetTodaysDeliveries,
  timeoutGetTodaysDeliveries,
} from './todaysDeliveries.redux'
import { setSelectedStore } from '../auth/auth.redux'
import { fetchUberDeliveries } from './todaysDeliveries.io'
import { TIMEOUT_GET_TODAY_DELIVERIES } from '../../const'
import { formatDateToStartOfDay, formatDateToEndOfDay } from '../../utils/string'
import { of, after } from 'fluture'
import { UserLoggedIn } from '../../utils/types'

const getTodaysDeliveriesEpic = {
  type: getTodaysDeliveries.toString(),
  do: ({ payload }, { auth }) =>
    fetchUberDeliveries(
      auth.token,
      formatDateToStartOfDay(new Date(), auth.selectedStore.timezone),
      formatDateToEndOfDay(new Date(), auth.selectedStore.timezone),
      auth.selectedStore.id
    ).chain(res =>
      payload ? of(setTodaysDeliveries(res.data)) : of([setTodaysDeliveries(res.data), timeoutGetTodaysDeliveries()])
    ),
}

const timeoutGetTodaysDeliveriesEpic = {
  type: timeoutGetTodaysDeliveries.toString(),
  do: (_, { auth }) =>
    UserLoggedIn.Yes.is(auth.isLoggedIn) ? after(TIMEOUT_GET_TODAY_DELIVERIES, onGetTodaysDeliveries()) : of(),
}

const onGetTodaysDeliveriesEpic = {
  type: [onGetTodaysDeliveries.toString(), setSelectedStore.toString()],
  do: ({ payload }) => of([setLoading(true), getTodaysDeliveries(payload)]),
}

export default [getTodaysDeliveriesEpic, timeoutGetTodaysDeliveriesEpic, onGetTodaysDeliveriesEpic]
