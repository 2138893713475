export const dssConfigurationMap = {
  dssStoreAssignation: {
    en: 'DSS - Store Assignation',
    fr: 'DSS - Attribution magasin',
  },
  getLatestDssList: {
    en: 'Get Latest DSS list',
    fr: 'Obtenir la dernière liste DSS',
  },
  density: {
    en: 'Density',
    fr: 'Densité',
  },
  export: {
    en: 'Export',
    fr: 'Export',
  },
  mallName: {
    en: 'Mall Name',
    fr: 'Centre commercial',
  },
  banner: {
    en: 'Banner',
    fr: 'Bannière',
  },
  storeNum: {
    en: 'Store #',
    fr: 'Magasin #',
  },
  province: {
    en: 'Province',
    fr: 'Province',
  },
  city: {
    en: 'City',
    fr: 'Ville',
  },
  dss: {
    en: 'DSS',
    fr: 'DSS',
  },
  submit: {
    en: 'Submit',
    fr: 'Soumettre',
  },
  areYouSure: {
    en: 'Are you sure',
    fr: 'Es-tu certain?',
  },
  unsavedChanges: {
    en: 'There are unsaved changes on the page. Are you sure you want to leave?',
    fr: 'Il y a des modifications non enregistrées sur la page. Êtes-vous sûr de vouloir quitter?',
  },
  stay: {
    en: 'Stay',
    fr: 'Rester',
  },
  leave: {
    en: 'Leave',
    fr: 'Quitter',
  },
  success: {
    en: 'success',
    fr: 'Succès',
  },
  error: {
    en: 'error',
    fr: 'Erreur',
  },
}

export const dssSnackbarMap = {
  getAllDssSuccess: {
    en: 'Received Latest Active Supervisors',
    fr: 'Reçu les derniers superviseurs actifs',
  },
  getAllDssError: {
    en: `Failed to get Latest Active Supervisors`,
    fr: `N'a pas pu obtenir les derniers superviseurs actifs`,
  },
  setStoreDssSuccess: {
    en: 'Updated DSS Store Assignation',
    fr: 'Attribution de magasin DSS mise à jour',
  },
  setStoreDssError: {
    en: 'Error',
    fr: 'Erreur',
  },
}
