import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchUberDeliveries = (token, fromDate, toDate, storeId) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: '/getTodaysDeliveries',
      params: {
        storeId,
        fromDate,
        toDate,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )
