import {
  getSearchResults,
  setSearchResultsFound,
  setSearchResultsSnackbar,
  setSearchResultsSnackbarMsg,
} from './searchResults.redux'
import { setOrders } from '../pastDeliveries/pastDeliveries.redux'
import { fetchSearchResults } from './searchResults.io'
import { setSearchResultsMap } from './searchResultsContent'

const getSearchResultsEpic = {
  type: getSearchResults.toString(),
  do: ({ payload: { search } }, { auth }) =>
    fetchSearchResults(
      auth.token,
      search,
      auth.userInfo.role.cata({
        Admin: () => auth.userInfo.stores,
        DistrictSupervisor: () => auth.userInfo.stores,
        Manager: () => [auth.selectedStore],
        Employee: () => [auth.selectedStore],
        None: () => null,
      })
    ).map(res => {
      if (!res.data.length) {
        return [
          setSearchResultsSnackbar(true),
          setSearchResultsSnackbarMsg(setSearchResultsMap.snackbarNoOrder[auth.language]),
        ]
      } else {
        return !res.data[0].trips.length
          ? [
              setSearchResultsSnackbar(true),
              setSearchResultsSnackbarMsg(setSearchResultsMap.snackbarNoTrip[auth.language]),
            ]
          : [setOrders(res.data[0].trips), setSearchResultsFound(true)]
      }
    }),
}

export default [getSearchResultsEpic]
