import { TextField, Select, MenuItem, InputAdornment, InputLabel, FormControl, makeStyles } from '@material-ui/core'
import { colors } from '../../colors'

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '4px 0px',
    width: '100%',

    '& .MuiInput-root': {
      backgroundColor: colors.lightBlue,
      marginTop: '20px !important',
    },
    '& label': {
      color: colors.black,
    },
  },
}))

const SelectComponent = props => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
      <Select
        labelId={`${props.name}-label`}
        id={props.name}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {props.options &&
          props.options.map(option => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}

const InputField = props => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <TextField
        id={props.name}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        label={props.label}
        onChange={props.onChange}
        multiline={props.multiline}
        disabled={props.disabled}
        required={props.required}
        error={props.error}
        helperText={props.helperText}
        onBlur={props.onBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.adornment} position="start">
              {props.inputAdornment}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  )
}

const fieldTypeComponentMap = {
  text: TextField,
  select: SelectComponent,
  input: InputField,
}

export const FormBuilder = ({ fields }) => {
  return (
    fields &&
    Object.keys(fields).map(fieldName => {
      const field = fields[fieldName]
      const { type } = field
      const FormComponent = fieldTypeComponentMap[type]

      return FormComponent && <FormComponent key={field.name} {...field} />
    })
  )
}
