import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingScreen } from '../../loading/loading'
import { Button, Card, CardHeader, CardActions } from '@material-ui/core'
import { tryLogin, setLoading } from '../auth/auth.redux'
import styled from 'styled-components'
import { MICROSOFT_LOGIN_URL } from '../../const'
import { useHistory } from 'react-router'

export const Login = () => {
  const { isLoading, loginError, isLoggedIn, language } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (window) {
      const code = new URLSearchParams(window.location.search).get('code')
      isLoggedIn.cata({
        Yes: () => {
          dispatch(setLoading(true))
          history.push('/todaysDeliveries')
        },
        No: () => {
          if (code) {
            dispatch(setLoading(true))
            dispatch(tryLogin(code))
          } else {
            window.location.href = MICROSOFT_LOGIN_URL
          }
        },
      })
    }
  }, [isLoggedIn, dispatch, history])

  return (
    <CenteredPage>
      {isLoading ? (
        <LoadingScreen />
      ) : !!loginError ? (
        <>
          <Card>
            <CardHeader title={loginError.error} titleTypographyProps={{ variant: 'body1' }} />
            <CardActions style={{ justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => (window.location.href = MICROSOFT_LOGIN_URL)}>
                Login
              </Button>
            </CardActions>
          </Card>
        </>
      ) : (
        <>Redirect to login / Rediriger vers la connexion</>
      )}
    </CenteredPage>
  )
}

const CenteredPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
