import { createAction, handleActions } from 'redux-actions'

export const getOrders = createAction('PAST_DELIVERIES_GET_ORDERS')
export const setOrders = createAction('PAST_DELIVERIES_SET_ORDERS')
export const setPastSearchResultsSnackbar = createAction('PAST_DELIVERIES_SET_PAST_SEARCH_RESULTS_SNACKBAR')

const INITIAL_STATE = {
  orders: [],
  showPastSearchResultsSnackbar: false,
}

export const pastDeliveriesReducer = handleActions(
  {
    [setOrders]: (state, { payload: orders }) => ({
      ...state,
      orders,
    }),
    [setPastSearchResultsSnackbar]: (state, { payload: showPastSearchResultsSnackbar }) => ({
      ...state,
      showPastSearchResultsSnackbar,
    }),
  },
  INITIAL_STATE
)
