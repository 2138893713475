import React from 'react'
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

export const DialogBuilder = ({ content, open, onDisagree, onAgree }) => {
  return (
    <Dialog open={open} onClose={onDisagree} disableBackdropClick={true} >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} variant="contained">
          {content.disagree}
        </Button>
        <Button onClick={onAgree} color="primary" variant="contained" autoFocus>
          {content.agree}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
