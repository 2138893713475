import axios from 'axios'
import { tryP } from 'fluture'
import { baseURL } from '../../const'

export const fetchOrders = (token, fromDate, toDate, storeId) =>
  tryP(() =>
    axios({
      method: 'GET',
      url: `/getTripsByDate`,
      params: {
        fromDate,
        toDate,
        storeId,
      },
      baseURL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  )

export const fetchPastDeliveriesOnScroll = (token, page, size, storeId) =>
  axios({
    method: 'GET',
    url: `/getTripsOnScroll`,
    params: {
      page,
      size,
      storeId,
    },
    baseURL,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
