export const globalDeliveriesMap = {
    deliveriesGlobalView: {
      en: 'Deliveries - Global View',
      fr: 'Livraisons - Vue Globale',
    },
    filters: {
        en: 'Filters',
        fr: 'Filtres'
    },
    density: {
        en: 'Density',
        fr: 'Densité'
    },
    export: {
        en: 'Export',
        fr: 'Export'
    },
    mallName: {
        en: 'Mall Name',
        fr: 'Centre commercial'
    },
    banner: {
        en: 'Banner',
        fr: 'Bannière'
    },
    storeNum: {
        en: 'Store #',
        fr: 'Magasin #'
    },
    country: {
        en: 'Country',
        fr: 'Pays'
    },
    province: {
        en: 'Province',
        fr: 'Province'
    },
    city: {
        en: 'City',
        fr: 'Ville'
    },
    phoneNumber: {
        en: 'Phone Number',
        fr: 'No Téléphone'
    },
    lastOrderDate: {
        en: 'Last Order Date',
        fr: 'Date dernière commande'
    },
    inPreparation: {
        en: 'In Preparation',
        fr: 'En préparation'
    },
    before3Pm: {
        en: 'Before 3PM (In Preparation)',
        fr: 'Avant 3PM (En préparation)'
    },
    awaitingPickup: {
        en: 'Awaiting Pickup',
        fr: 'En attente de ramassage'
    },
    outForDelivery: {
        en: 'Out for delivery',
        fr: 'Livraison en cours'
    },
    cancelled: {
        en: 'Cancelled',
        fr: 'Annulées'
    },
    completed: {
        en: 'Completed',
        fr: 'Complétées'
    },
    todaysDeliveries: {
        en: 'Today\'s Deliveries',
        fr: 'Livraisons du jour'
    }
  }
  