import {
  onGetStoresWithTodaysDeliveries,
  timeoutGetStoresWithTodaysDeliveries,
  setLoading,
  getStoresWithTodaysDeliveries,
  setStoresWithTodaysDeliveries,
} from './globalDeliveries.redux'
import { fetchStoresWithTodaysDeliveries } from './globalDeliveries.io'
import { TIMEOUT_GET_GLOBAL_VIEW_DELIVERIES } from '../../const'
import { formatDateToStartOfDay, formatDateToEndOfDay } from '../../utils/string'
import { of, after } from 'fluture'
import { UserLoggedIn, Role } from '../../utils/types'

const getStoresWithTodaysDeliveriesEpic = {
  type: getStoresWithTodaysDeliveries.toString(),
  do: ({ payload }, { auth }) =>
    fetchStoresWithTodaysDeliveries(
      auth.token,
      formatDateToStartOfDay(new Date(), auth.selectedStore.timezone),
      formatDateToEndOfDay(new Date(), auth.selectedStore.timezone),
      auth.userInfo.stores
    ).chain(res =>
      payload
        ? of(setStoresWithTodaysDeliveries(res.data))
        : of([setStoresWithTodaysDeliveries(res.data), timeoutGetStoresWithTodaysDeliveries()])
    ),
}

const timeoutGetStoresWithTodaysDeliveriesEpic = {
  type: timeoutGetStoresWithTodaysDeliveries.toString(),
  do: (_, { auth }) =>
    UserLoggedIn.Yes.is(auth.isLoggedIn) &&
    (Role.Admin.is(auth.userInfo.role) || Role.DistrictSupervisor.is(auth.userInfo.role))
      ? after(TIMEOUT_GET_GLOBAL_VIEW_DELIVERIES, onGetStoresWithTodaysDeliveries())
      : of(),
}

const onGetStoresWithTodaysDeliveriesEpic = {
  type: onGetStoresWithTodaysDeliveries.toString(),
  do: ({ payload }) => of([setLoading(true), getStoresWithTodaysDeliveries(payload)]),
}

export default [
  timeoutGetStoresWithTodaysDeliveriesEpic,
  onGetStoresWithTodaysDeliveriesEpic,
  getStoresWithTodaysDeliveriesEpic,
]
