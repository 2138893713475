/**
 *
 * @param {Object[]} items items
 * @param {Object} filters filters
 * @returns {Object[]} filtered array of object data
 */
export const filterItemsByOrderStatus = (items, filters) => {
  const filterKeys = Object.keys(filters)
  return items.filter(eachObj =>
    filterKeys.every(eachKey => {
      if (!filters[eachKey].length) {
        return false
      }
      return filters[eachKey].includes(eachObj[eachKey])
    })
  )
}

/**
 *
 * @param {Object[]} items items
 * @param {Object} filterParameter filters
 * @returns {String|Number} count of the number of items matching the filterParameter, where a dash is utilized for no result
 */
export const displayCountOfFilteredItemsByOrderStatus = (uberDeliveries, filterParameter) => {
  const countOfStatus = filterItemsByOrderStatus(uberDeliveries, filterParameter).length
  return countOfStatus === 0 ? '-' : countOfStatus
}
