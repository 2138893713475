import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { setOpenCancelPopup, setTripIdToCancel } from '../todaysDeliveries/todaysDeliveries.redux'
import { cancelTripDialogMap } from './cancelTripContent'

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      marginTop: '10px',
      padding: '2px 30px', 
    }
  }
}))

export const CancelTripButton = ({tripId, language}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <div className={classes.root} >
      <Button
        variant='contained'
        color='primary'
        onClick={(event) => {
          event && event.stopPropagation()
          dispatch(setOpenCancelPopup(true))
          dispatch(setTripIdToCancel(tripId))}
        }
      >
      {cancelTripDialogMap.cancelText[language]}
      </Button>
    </div>
  )
}