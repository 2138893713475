import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Login } from './modules/login/login'
import { TodaysDeliveries } from './modules/todaysDeliveries/todaysDeliveries'
import { InPreparation } from './modules/inPreparation/inPreparation'
import { AwaitingPickup } from './modules/awaitingPickup/awaitingPickup'
import { OutForDelivery } from './modules/outForDelivery/outForDelivery'
// import { Return } from './modules/return/return'
import { Cancelled } from './modules/cancelled/cancelled'
import { Completed } from './modules/completed/completed'
import { UberConnect } from './modules/uberConnect/uberConnect'
import { PastDeliveries } from './modules/pastDeliveries/pastDeliveries'
import { StoreConfiguration } from './modules/storeConfiguration/storeConfiguration'
import { NewDelivery } from './modules/newDelivery/newDelivery'
import { PostalCodeConfiguration } from './modules/postalCodeConfiguration/postalCodeConfiguration'
import { GlobalStoreConfiguration } from './modules/storeConfiguration/globalStoreConfiguration'
import { GlobalDeliveries } from './modules/globalDeliveries/globalDeliveries'
import { DSSConfiguration } from './modules/dssConfiguration/dssConfiguration'

const commonRoutes = [
  <Route key={'todaysDeliveries'} component={TodaysDeliveries} exact path="/todaysDeliveries" />,
  <Route key={'inPreparation'} component={InPreparation} exact path="/inPreparation" />,
  <Route key={'awaitingPickup'} component={AwaitingPickup} exact path="/awaitingPickup" />,
  <Route key={'outForDelivery'} component={OutForDelivery} exact path="/outForDelivery" />, // add return Route when needed <Route key={'return'} component={Return} exact path="/return" />
  <Route key={'cancelled'} component={Cancelled} exact path="/cancelled" />,
  <Route key={'completed'} component={Completed} exact path="/completed" />,
  <Route key={'uberConnect'} component={UberConnect} exact path="/uberConnect" />,
  <Route key={'pastDeliveries'} component={PastDeliveries} exact path="/pastDeliveries" />,
]

const seniorPositionRoutes = [
  <Route key={'storeConfiguration'} component={StoreConfiguration} exact path="/storeConfiguration" />,
  <Route key={'newDelivery'} component={NewDelivery} exact path="/newDelivery" />,
]

const dssPositionRoutes = [
  <Route key={'globalDeliveries'} component={GlobalDeliveries} exact path="/globalDeliveries" />,
  <Route
    key={'globalStoreConfiguration'}
    component={GlobalStoreConfiguration}
    exact
    path="/globalStoreConfiguration"
  />,
]

const adminPositionRoutes = [
  <Route key={'postalCodeConfiguration'} component={PostalCodeConfiguration} exact path="/postalCodeConfiguration" />,
  <Route key={'globalDeliveries'} component={GlobalDeliveries} exact path="/globalDeliveries" />,
  <Route
    key={'globalStoreConfiguration'}
    component={GlobalStoreConfiguration}
    exact
    path="/globalStoreConfiguration"
  />,
  <Route key={'dssConfiguration'} component={DSSConfiguration} exact path="/dssConfiguration" />,
]

const redirectRoute = path => <Route key={path} render={() => <Redirect to={`/${path}`} />} />

const getRoutes = role =>
  role.cata({
    Admin: () => [...commonRoutes, ...seniorPositionRoutes, ...adminPositionRoutes, redirectRoute('todaysDeliveries')],
    DistrictSupervisor: () => [
      ...commonRoutes,
      ...seniorPositionRoutes,
      ...dssPositionRoutes,
      redirectRoute('todaysDeliveries'),
    ],
    Manager: () => [...commonRoutes, ...seniorPositionRoutes, redirectRoute('todaysDeliveries')],
    Employee: () => [...commonRoutes, redirectRoute('todaysDeliveries')],
    None: () => [],
  })

export const Routes = ({ role, isLoggedIn }) => (
  <Switch>
    {isLoggedIn.cata({
      Yes: () => getRoutes(role),
      No: () => <Route component={Login} path="/" />,
    })}
  </Switch>
)
