export const patternValidation = (pattern, value) => {
  return pattern.test(value)
}

export const requiredValidation = value => {
  if (value && typeof value === 'object') {
    return requiredValidation(value.value)
  }
  return value === '' || value === null || value === undefined
}
