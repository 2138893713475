const { format, isToday } = require('date-fns')
const { fr } = require('date-fns/locale')

/**
 * Group trips by date
 *
 * @param {Array} trips
 *
 * Formats date to required format
 *    2021-03-26 => March 26, 2021: Friday
 *
 * For every new date a new key (date) will be created and
 * all the trips related to that date will be assigned to that key
 *
 * Then tripsGroupedByDate will be formatted to an array of objects
 *
 * @returns {Array} - Each entry is an object with two properties
 *                                  1) Date
 *                                  2) Group of trips for the respective date
 */

export const groupTripsByDate = trips =>
  trips.reduce((acc, trip) => {
    const dateEn = isToday(new Date(trip.updatedAt)) ? 'Today' : format(new Date(trip.updatedAt), 'LLLL dd, yyyy: EEEE')
    const dateFr = isToday(new Date(trip.updatedAt))
      ? `Aujourd'hui`
      : format(new Date(trip.updatedAt), 'dd LLLL, yyyy: EEEE', {
          locale: fr,
        })
    const dateIndex = acc.findIndex(x => x.date.en === dateEn)
    if (dateIndex < 0) {
      acc.push({ date: { en: dateEn, fr: dateFr }, trips: [trip] })
    } else {
      acc[dateIndex]['trips'].push(trip)
    }
    return acc
  }, [])

export const countCompletedTrip = trips => trips.filter(x => x.latestOrderStatus === 'COMPLETED').length
