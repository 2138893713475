import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DialogBuilder } from '../reusables/dialogBuilder'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import {
  cancelTrip,
  setCancelledSnackBar,
  setOpenCancelPopup,
  setRedirectToAwaitingPickup,
} from '../todaysDeliveries/todaysDeliveries.redux'
import { cancelTripDialogMap, cancelTripSnackbarMap } from './cancelTripContent'

const cancelTripDialog = {
  text: `Are you sure you want to cancel this trip?`,
  disagree: `No, I don’t`,
  agree: `Yes, I do`,
}

export const CancelTripDialog = () => {
  const { cancelledSnackBar, openCancelPopup, tripIdToCancel, redirectToAwaitingPickup } = useSelector(
    state => state.today
  )
  const { language } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (redirectToAwaitingPickup) {
      history.push('/awaitingPickup')
      dispatch(setRedirectToAwaitingPickup(false))
    }
  }, [redirectToAwaitingPickup, dispatch, history])

  return (
    <>
      <DialogBuilder
        content={{ text: cancelTripDialogMap.text[language], disagree: cancelTripDialogMap.disagree[language], agree: cancelTripDialogMap.agree[language] }}
        open={openCancelPopup}
        onDisagree={() => dispatch(setOpenCancelPopup(false))}
        onAgree={() => {
          dispatch(cancelTrip(tripIdToCancel))
          dispatch(setOpenCancelPopup(false))
        }}
      />
      <SnackbarBuilder
        contentText={ cancelledSnackBar.message ? cancelTripSnackbarMap[cancelledSnackBar.message][language] : '' }
        isOpen={cancelledSnackBar.show}
        handleClose={() => dispatch(setCancelledSnackBar({ ...cancelledSnackBar, show: false, message: '' }))}
        severityLevel={cancelledSnackBar.success ? 'success' : 'error'}
        location={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  )
}
