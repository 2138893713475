import React from 'react'
import { useSelector } from 'react-redux'
import { filterItemsByOrderStatus } from '../../utils/filter'
import { NoResults } from '../reusables/noResults'
import { LoadingScreen } from '../../loading/loading'
import { AWAITING_PICKUP_FILTER } from '../../const'
import { Grid } from '@material-ui/core'
import { OrderCards } from '../orderCards/orderCards'
import { CancelTripDialog } from '../cancelTrip/cancelTripDialog'
import { todaysDeliveriesTitleMap } from '../todaysDeliveries/todaysDeliveriesContent'

export const AwaitingPickup = () => {
  const { todaysDeliveries, isLoading } = useSelector(state => state.today)
  const { language } = useSelector(state => state.auth)
  const awaitingPickup = filterItemsByOrderStatus(todaysDeliveries.trips, AWAITING_PICKUP_FILTER)

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container alignItems="center" justify="center">
          {awaitingPickup.length === 0 ? (
            <NoResults />
          ) : (
            <OrderCards data={awaitingPickup} title={todaysDeliveriesTitleMap.awaitingPickup[language]} orderBy={'updatedAt'} />
          )}
        </Grid>
      )}
      <CancelTripDialog />
    </>
  )
}
