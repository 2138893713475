import authEpics from '../modules/auth/auth.epics'
import todaysDeliveriesEpics from '../modules/todaysDeliveries/todaysDeliveries.epics'
import pastDeliveriesEpics from '../modules/pastDeliveries/pastDeliveries.epics'
import searchResultsEpics from '../modules/searchResults/searchResults.epics'
import cancelTripEpics from '../modules/cancelTrip/cancelTrip.epics'
import newDeliveryEpics from '../modules/newDelivery/newDelivery.epics'
import storeConfigurationEpics from '../modules/storeConfiguration/storeConfiguration.epics'
import postalCodeConfigurationEpics from '../modules/postalCodeConfiguration/postalCodeConfiguration.epics'
import globalDeliveriesEpics from '../modules/globalDeliveries/globalDeliveries.epics'
import dssConfigurationEpics from '../modules/dssConfiguration/dssConfiguration.epics'

export const epics = {
  authEpics,
  todaysDeliveriesEpics,
  pastDeliveriesEpics,
  searchResultsEpics,
  cancelTripEpics,
  newDeliveryEpics,
  storeConfigurationEpics,
  postalCodeConfigurationEpics,
  globalDeliveriesEpics,
  dssConfigurationEpics,
}
