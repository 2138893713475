import { authReducer } from '../modules/auth/auth.redux'
import { searchResultsReducer } from '../modules/searchResults/searchResults.redux'
import { todaysDeliveriesReducer } from '../modules/todaysDeliveries/todaysDeliveries.redux'
import { pastDeliveriesReducer } from '../modules/pastDeliveries/pastDeliveries.redux'
import { postalCodeConfigReducer } from '../modules/postalCodeConfiguration/postalCodeConfiguration.redux'
import { globalDeliveriesReducer } from '../modules/globalDeliveries/globalDeliveries.redux'
import { dssConfigurationReducer } from '../modules/dssConfiguration/dssConfiguration.redux'
import { storeConfigReducer } from '../modules/storeConfiguration/storeConfiguration.redux'

export const reducers = {
  auth: authReducer,
  today: todaysDeliveriesReducer,
  past: pastDeliveriesReducer,
  search: searchResultsReducer,
  postalCodeConfig: postalCodeConfigReducer,
  globalDeliveries: globalDeliveriesReducer,
  districtSupervisors: dssConfigurationReducer,
  storeConfiguration: storeConfigReducer,
}
