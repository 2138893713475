import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

export const Popup = ({
  isOpen,
  onConfirmClick,
  onDiscardClick,
  dialogTitle,
  dialogContentText,
  cancelButtonText,
  submitButtonText,
  showCancelButton = true
}) => (
  <div>
    <Dialog open={isOpen} onClose={onDiscardClick}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancelButton
        &&
        <Button onClick={onDiscardClick} color="primary">
          {cancelButtonText}
        </Button>
        }
        <Button onClick={onConfirmClick} color="primary" autoFocus>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)
