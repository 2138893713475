import { STATUS_LIST, TIMEZONE, DEFAULT_TIMEZONE, DEFAULT_THRESHOLD, LANGUAGE } from '../const'
import momentTz from 'moment-timezone'

const { get, defaults } = require('partial.lenses')

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

/**
 * uber status can be grouped into a status order
 * eg.: uber status 'ARRIVED_AT_PICKUP' and 'SCHEDULED' are both status order "AWAITING_PICKUP"
 *
 * @param {String} latestOrderStatus uber status of order as set in MongoDB
 * @returns {String} status of order
 */
export const determineOrderStatus = latestOrderStatus => {
  return STATUS_LIST[latestOrderStatus] || ''
}

/**
 *
 * @param {string} timezone
 * @returns Timezone mapped to proper naming convention
 */
export const checkTimeZone = timezone => (TIMEZONE[timezone] ? TIMEZONE[timezone] : timezone)

/**
 *
 * @param {Object} storeData
 * @returns index of the day, range from 0 - 6
 */
export const getTodaysIndex = storeData =>
  momentTz()
    .tz(TIMEZONE[storeData.timezone] || DEFAULT_TIMEZONE)
    .toDate()
    .getDay()

/**
 *
 * @param {*} storeData
 * @param {*} dayIndex
 * @returns the day's threshold time of the store
 */
export const getDaysThresholdByIndex = (storeData, dayIndex) => {
  const today = DAYS[dayIndex]
  return (storeData.sameDayThresholdTimeEst && storeData.sameDayThresholdTimeEst[today]) || DEFAULT_THRESHOLD
}

/**
 *
 * @param {Object} orderData
 * @param {*} currentDateTime
 * @param {Object} store
 * @returns Boolean - if threshold warning needs to be displayed;
 * threshold warning should be visible 1 hour before store threshold time and displayed on orders created before today 3pm
 */
export const isThresholdWarningNeeded = (orderData, currentDateTime, store) => {
  if (orderData.latestOrderStatus === 'ORDER_CREATED_IN_SFCC' && orderData.isBeforeTimeThreshold) {
    const storeTimeZone = store.timezone ? TIMEZONE[store.timezone] : TIMEZONE[DEFAULT_TIMEZONE]
    const todaysIndex = getTodaysIndex(store)
    const yesterdaysIndex = todaysIndex === 0 ? 6 : todaysIndex - 1

    const todaysThresholdTime = getDaysThresholdByIndex(store, todaysIndex)
    const yesterdaysThresholdTime = getDaysThresholdByIndex(store, yesterdaysIndex)

    const todayThreshold = momentTz.tz(`${currentDateTime.format('yyyy-MM-DD')} ${todaysThresholdTime}`, storeTimeZone)
    const thresholdYesterday = momentTz.tz(
      `${currentDateTime.clone().subtract(1, 'days').format('yyyy-MM-DD')} ${yesterdaysThresholdTime}`,
      storeTimeZone
    )

    const updatedAtTime = momentTz.tz(orderData.updatedAt, storeTimeZone)
    const currentTime = momentTz.tz(currentDateTime, storeTimeZone)
    const hourBeforeTodaysThreshold = momentTz.tz(todayThreshold.clone().subtract(1, 'hours').format(), storeTimeZone)

    return updatedAtTime.isBetween(thresholdYesterday, hourBeforeTodaysThreshold) &&
      currentTime.isSameOrBefore(hourBeforeTodaysThreshold)
      ? false
      : orderData.isBeforeTimeThreshold
  } else {
    return false
  }
}

export const getStoreIds = stores => stores.map(store => ({ id: store.id }))

export const changeLanguage = language => (language === LANGUAGE.EN ? LANGUAGE.FR : LANGUAGE.EN)

export const getStoreById = (id, stores) => stores.find(store => store.id === id)

export const setStoreConfigThresholdTimes = (thresholdLabels, store) => 
  Object.keys(thresholdLabels).reduce((acc, day) => {
    acc[day] = get(['sameDayThresholdTimeEst', day, defaults(DEFAULT_THRESHOLD)], store)
    return acc
  },{})
