import React from 'react'
import { useSelector } from 'react-redux'
import { ComingSoon } from '../reusables/comingSoon'
import { LoadingScreen } from '../../loading/loading'

export const UberConnect = () => {
  const { isLoading } = useSelector(state => state.today)

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <ComingSoon />
      )}
    </>
  )
}
